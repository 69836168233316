import { writable } from 'svelte/store';
import StartScreen from '@/lib/screens/StartScreen.svelte';
import type { IEvent } from '@/types';
import soundManager from '@pkg/sound-manager';

export const popup = (() => {
	const { subscribe, update, set } = writable({
		active: false,
		data: null,
	});

	return {
		subscribe,
		show: (data: IEvent['data']) => {
			soundManager.filter(true);

			update(n => ({
				...n,
				active: false,
			}));
			requestAnimationFrame(() => {
				update(n => ({
					data,
					active: true,
				}));
			});
		},
		hide: () => {
			soundManager.filter(false);

			update(data => ({
				...data,
				active: false,
			}));
		},
	};
})();

export const currentScreen = writable(StartScreen);

export const blackCurtain = (() => {
	const { subscribe, set } = writable(true);

	return {
		subscribe,
		hide: () => set(false),
		show: () => set(true),
	};
})();

export const startWalkPose = (() => {
	const { subscribe, set } = writable(true);

	return {
		subscribe,
		hide: () => set(false),
	};
})();

export const music = (() => {
	const { subscribe, set, update } = writable(true);

	return {
		subscribe,
		toggle: () => update((n) => {
			const newValue = !n;
			soundManager.mute(!newValue);
			return newValue;
		}),
	};
})();

export const menu = (() => {
	const { subscribe, set, update } = writable(false);

	return {
		subscribe,
		toggle: () => update((n) => {
			const newValue = !n;
			soundManager.filter(newValue);
			return newValue;
		}),
		hide: () => {
			soundManager.filter(false);
			set(false);
		},
	};
})();

export const onboarding = (() => {
	const { subscribe, update, set } = writable({
		scroll: true,
		click: true,
	});

	return {
		subscribe,
		hideScroll: () => update((data) => ({
			...data,
			scroll: false,
		})),
		hideClick: () => update((data) => ({
			...data,
			click: false,
		})),
	};
})();

export const cookiesAccept = writable(false);
