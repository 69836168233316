import { Loader } from 'excalibur';
import type { Loadable } from 'excalibur/build/dist/Interfaces/Loadable';

export default class CustomLoader extends Loader {
	constructor(loadables?: Loadable<any>[]) {
		super(loadables);

		this.suppressPlayButton = true;
	}

	async load(): Promise<Loadable<any>[]> {
		await Promise.all([
			super.load(),
			this.areResourcesLoaded(),
		]);

		return this.data;
	}
}
