<script lang='ts'>
	import { fade } from 'svelte/transition';
	import { assetsUrl } from '@/utils.js';

	let img = {
		name: 'walk',
		ext: 'png',
	};
	let alt: string = 'start-walk-pose';
	let path = 'static/';
</script>

<picture class='start-walk-pose' transition:fade>
	<source srcset='{assetsUrl(`${path}${img.name}.webp`)}' type='image/webp'>
	<img alt='{alt}' src='{assetsUrl(`${path}${img.name}.${img.ext}`)}'>
</picture>

<style lang='scss'>
  .start-walk-pose {
    position: fixed;
    left: 0;
    right: 0;
    bottom: calc(101px * var(--screen-height-ratio));
    display: flex;
    justify-content: center;

    img {
      width: auto;
      height: calc(173px * var(--screen-height-ratio));
    }
  }
</style>
