<script lang='ts'>
	import { currentScreen } from '@/stores';
	import OnBoardingScreen from '@/lib/onboarding/OnBoardingScreen.svelte';
	import ClickInfo from '@/lib/onboarding/ClickInfo.svelte';
	import Scroll from '@/lib/icons/Scroll.svelte';

	const next = () => {
		$currentScreen = ClickInfo;
	};
</script>

<OnBoardingScreen {next}>
	<Scroll />
	<div class='caption'>
		Удерживайте свайп, чтобы <br> идти вперед
	</div>
</OnBoardingScreen>
