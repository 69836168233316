<a class='playlist-link' href='https://open.zvuk.com/cLQ0/icjt8fil' target='_blank'>Слушать плейлист</a>

<style lang='scss'>
  .playlist-link {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 30px;
    background: linear-gradient(90deg, #BD49F4 3.36%, #6A75F5 97.55%);
    text-decoration: none;
  }
</style>
