import JSONSpriteSheet from '@pkg/scene/partials/json-spritesheet';
import CustomImageSource from '@pkg/scene/partials/custom-image-source';
import { assetsUrl } from '@/utils';

export const scene = {
	skyline: new CustomImageSource(`${assetsUrl(`assets/skyline${JSONSpriteSheet.canUseWebP() ? '.webp' : '.png'}`)}`),
	buildings: new JSONSpriteSheet(assetsUrl('assets/buildings/buildings.json')),
	fpAnim: new JSONSpriteSheet(assetsUrl('assets/fp-anims/fp-anims.json')),
};

export const events = {
	epoch1970: {
		static: new JSONSpriteSheet(assetsUrl('assets/epoch70/static.json')),
		birthday: new JSONSpriteSheet(assetsUrl('assets/epoch70/1.json')),
		bambatta: new JSONSpriteSheet(assetsUrl('assets/epoch70/bambatta-0.json')),
		bambatta1: new JSONSpriteSheet(assetsUrl('assets/epoch70/bambatta-1.json')),
		bambatta2: new JSONSpriteSheet(assetsUrl('assets/epoch70/bambatta-2.json')),
	},
	epoch1980: {
		static: new JSONSpriteSheet(assetsUrl('assets/epoch80/static.json')),
		car: new JSONSpriteSheet(assetsUrl('assets/epoch80/5-0.json')),
		car1: new JSONSpriteSheet(assetsUrl('assets/epoch80/5-1.json')),
		car2: new JSONSpriteSheet(assetsUrl('assets/epoch80/5-2.json')),
		car3: new JSONSpriteSheet(assetsUrl('assets/epoch80/5-3.json')),
	},
	epoch1990: {
		static: new JSONSpriteSheet(assetsUrl('assets/epoch90/static.json')),
		cops1: new JSONSpriteSheet(assetsUrl('assets/epoch90/4-0.json')),
		cops2: new JSONSpriteSheet(assetsUrl('assets/epoch90/4-1.json')),
		cops3: new JSONSpriteSheet(assetsUrl('assets/epoch90/4-2.json')),
		cops4: new JSONSpriteSheet(assetsUrl('assets/epoch90/4-3.json')),
		cops5: new JSONSpriteSheet(assetsUrl('assets/epoch90/4-4.json')),
	},
	epoch2000: {
		static: new JSONSpriteSheet(assetsUrl('assets/epoch00/static.json')),
	},
	epoch2010: {
		static: new JSONSpriteSheet(assetsUrl('assets/epoch10/static.json')),
		final: new JSONSpriteSheet(assetsUrl('assets/epoch10/6-0.json')),
		final1: new JSONSpriteSheet(assetsUrl('assets/epoch10/6-1.json')),
	},
};

export const player = {
	walk: new JSONSpriteSheet(assetsUrl('assets/walk/walk.json')),
};
