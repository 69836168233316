<script lang='ts'>
	export let progress: number;
</script>

<div class='loader'>
	Загрузка {progress}%
</div>

<style lang='scss'>
  .loader {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #FFFFFF;
  }
</style>
