import { Sound } from 'excalibur';

export default class DJTurntable {
	readonly duration: number = 0;
	private readonly nonReversedBuffer!: AudioBuffer;
	private readonly reversedBuffer!: AudioBuffer;
	private readonly nonReversedData!: Float32Array;
	private readonly reversedData!: Float32Array;
	private readonly gainNode!: GainNode;
	private mainGainNode!: GainNode;
	private audioSource: AudioBufferSourceNode;
	private isReversed: boolean = false;
	private biquadFilterNode!: BiquadFilterNode;

	constructor(private readonly audioContext: AudioContext, readonly sound: Sound, mute = false) {
		this.nonReversedData = Float32Array.from(sound.data.getChannelData(0));
		this.nonReversedBuffer = this.audioContext.createBuffer(1, sound.data.length, sound.data.sampleRate);
		this.nonReversedBuffer.getChannelData(0).set(this.nonReversedData);

		this.reversedData = Float32Array.from(sound.data.getChannelData(0).slice().reverse());
		this.reversedBuffer = this.audioContext.createBuffer(1, sound.data.length, sound.data.sampleRate);
		this.reversedBuffer.getChannelData(0).set(this.reversedData);

		this.gainNode = this.audioContext.createGain();
		this.mainGainNode = this.audioContext.createGain();
		this.biquadFilterNode = this.audioContext.createBiquadFilter();
		this.biquadFilterNode.type = 'allpass';
		this.biquadFilterNode.frequency.value = 2000;

		this.gainNode.connect(this.mainGainNode);
		this.mainGainNode.connect(this.biquadFilterNode);
		this.biquadFilterNode.connect(this.audioContext.destination);
		this.duration = this.nonReversedBuffer.duration;

		this.mute(mute);
	}

	get volume() {
		return this.gainNode.gain.value;
	}

	set volume(val) {
		this.gainNode.gain.value = val;
	}

	filter(val: boolean) {
		this.biquadFilterNode.type = val ? 'lowpass' : 'allpass';
	}

	play(offset = 0) {
		const buffer = this.isReversed ? this.reversedBuffer : this.nonReversedBuffer;
		const startOffset = this.isReversed ? this.duration - offset : offset;

		this.pause();
		this.audioSource = this.audioContext.createBufferSource();
		this.audioSource.buffer = buffer;
		this.audioSource.loop = true;
		this.audioSource.connect(this.gainNode);
		this.audioSource.start(0, startOffset);
	}

	turn(value: number, isReversed: boolean, progress: number) {
		this.updateSpeed(value, isReversed, progress);
	}

	pause() {
		this.audioSource && this.audioSource.stop();
	}

	status() {
		return {
			gain: this.gainNode.gain.value,
			mainGain: this.mainGainNode.gain.value,
			state: this.audioContext.state,
		};
	}

	mute(val: boolean) {
		this.mainGainNode.gain.value = val ? 0 : 1;
	}

	private updateSpeed(playbackSpeed, isReversed, progress) {
		if (!this.audioSource) return;

		isReversed !== this.isReversed && this.changeDirection(isReversed, progress);

		const { currentTime } = this.audioContext;
		const absPlaybackSpeed = Math.abs(playbackSpeed);

		this.audioSource.playbackRate.cancelScheduledValues(currentTime);
		this.audioSource.playbackRate.linearRampToValueAtTime(Math.max(.001, absPlaybackSpeed), currentTime);
	}

	private changeDirection(isReversed, secondsPlayed) {
		this.isReversed = isReversed;
		this.play(secondsPlayed);
	}
}
