#version 300 es
#ifdef GL_FRAGMENT_PRECISION_HIGH
  precision highp float;
#else
  precision mediump float;
#endif


// our texture
uniform sampler2D u_image;

// color blind type
uniform float u_value;

// the texCoords passed in from the vertex shader.
in vec2 v_texcoord;

out vec4 fragColor;

void main() {
    vec4 tex = texture(u_image, v_texcoord);
    float avg = 0.2126 * tex.r + 0.7152 * tex.g + 0.0722 * tex.b;
    fragColor = vec4(avg, avg, avg, 1.0) * u_value + (1.0 - u_value) * tex;
}
