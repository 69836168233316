<script lang='ts'>
	import type { IEvent } from '@/types';
	import { onboarding, popup } from '@/stores';
	import Big from '@/lib/icons/paint/Big.svelte';
	import Small1 from '@/lib/icons/paint/Small1.svelte';
	import Small2 from '@/lib/icons/paint/Small2.svelte';
	import Small3 from '@/lib/icons/paint/Small3.svelte';
	import Small4 from '@/lib/icons/paint/Small4.svelte';
	import Small5 from '@/lib/icons/paint/Small5.svelte';
	import Circle from '@/lib/icons/paint/Circle.svelte';
	import Crown from '@/lib/icons/paint/Crown.svelte';

	export let event: IEvent;

	const style = `
	top: calc(100% + ${event.pos.y / 2}px * var(--screen-height-ratio));
  transform: translate(calc(50vw + (${event.pos.x / 2}px - var(--scene-offset) / 1.5) * var(--screen-height-ratio)), -100%);
	`;

	$:modes = event.data.mode.split(' ');
	const openPopup = () => {
		onboarding.hideClick();
		popup.show(event.data);
	};
</script>

{#if event.data}
	<div class='event {event.data.mode || "" }' {style} on:click={openPopup}>
		{#if event.data.caption}
			<div class='caption'>{event.data.caption}</div>
		{/if}
		<div class='year'>
			{event.data.year}

			{#if modes.includes('-big')}
				<Big />
			{/if}

			{#if modes.includes('-circle')}
				<Circle />
			{/if}

			{#if modes.includes('-crown')}
				<Crown />
			{/if}

			{#if modes.includes('-small1')}
				<Small1 />
			{/if}

			{#if modes.includes('-small2')}
				<Small2 />
			{/if}

			{#if modes.includes('-small3')}
				<Small3 />
			{/if}

			{#if modes.includes('-small4')}
				<Small4 />
			{/if}

			{#if modes.includes('-small5')}
				<Small5 />
			{/if}
		</div>
		<div class='text'>{event.data.title}</div>
	</div>
{/if}


<style lang='scss'>
  .event {
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    font-family: $TTHoves, sans-serif;
    pointer-events: auto;
    cursor: pointer;
    white-space: pre-line;

    &:global(.-big) {
      .year {
        font-size: 140px;
      }

      .text {
        margin-top: 15px;
        text-align: center;
      }
    }

    &.-circle {
      .caption {
        margin-left: 140px;
      }
    }
  }

  .caption {
    font-size: 26px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
  }

  .year {
    width: fit-content;
    position: relative;
    font-size: 52px;
    font-weight: 900;
    line-height: 0.9;
    color: #FFF;
  }

  .text {
    margin-top: 10px;
    position: relative;
    font-size: 16px;
    font-weight: 900;
    line-height: 20px;
    color: #FFF;
    z-index: 1;
  }
</style>
