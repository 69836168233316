<script lang='ts'>
	import { onDestroy, onMount } from 'svelte';
	import controller from '@pkg/scene/controller.js';
	import { currentScreen } from '@/stores.js';
	import Button from '@/lib/Button.svelte';
	import Loader from '@/lib/Loader.svelte';
	import Zvuk from '@/lib/icons/Zvuk.svelte';
	import Image from '@/lib/Image.svelte';
	import DisclaimerScreen from '@/lib/screens/DisclaimerScreen.svelte';
	import sm from '@pkg/sound-manager';
	import Bugsnag from '@bugsnag/js';

	let progress = 0;
	let rafId;
	let loaded = false;

	const next = () => {
		$currentScreen = DisclaimerScreen;

		requestAnimationFrame(async () => {
			await controller.start();
			await sm.audioContext.resume();

			if (sm.audioContext.state === 'suspended') {
				Bugsnag.notify(new Error('audio context is suspended'));
			}
		});
	};

	onMount(() => {
		controller.prepareScene().then(() => {
			loaded = true;
		});

		rafId = requestAnimationFrame(function loop() {
			progress = Math.ceil(controller.getSceneLoaders().scene1970.progress * 100);

			if (!loaded) rafId = requestAnimationFrame(loop);
		});
	});

	onDestroy(() => {
		cancelAnimationFrame(rafId);
	});
</script>

<div class='screen start-screen'>
	<div class='container -tiny'>
		<div class='head'>
			<Zvuk />
		</div>
		<div class='hero'>
			<Image class='cover' img={{name: 'cover', ext: 'png'}} />
			<Image class='logo' img={{name: 'main-title', ext: 'png'}} />
		</div>
		{#if loaded}
			<Button on:click={next}>Вперед</Button>
		{:else}
			<Loader {progress} />
		{/if}
	</div>
</div>

<style lang='scss'>
  .start-screen {
    display: flex;

    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }

  .head {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .hero {
    position: relative;

    :global(.cover) {
      position: relative;
      top: -45px;
    }

    :global(.cover img) {
      width: clamp(0px, 58.7vh, 395px);

      @include breakpoint-up($tablet) {
        width: clamp(0px, 61.7vh, 474px);
      }
    }

    :global(.logo) {
      position: absolute;
      left: -15px;
      bottom: 0;
    }

    :global(.logo img) {
      width: clamp(0px, 64.4vh, 433px);

      @include breakpoint-up($tablet) {
        width: clamp(0px, 67.7vh, 520px);
      }
    }
  }
</style>
