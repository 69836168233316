import colorBlindCorrectSource from './fragment.glsl';
import type { PostProcessor } from 'excalibur';
import { ScreenShader, Shader, VertexLayout } from 'excalibur';

export class GrayScalePostProcessor implements PostProcessor {
	private screenShader: ScreenShader;

	set value(val: number) {
		if (this.screenShader) {
			const shader = this.getShader();
			shader.use();
			shader.setUniformFloat('u_value', val);
		}
	}

	initialize(gl): void {
		this.screenShader = new ScreenShader(gl, colorBlindCorrectSource);
	}

	getLayout(): VertexLayout {
		return this.screenShader.getLayout();
	}

	getShader(): Shader {
		return this.screenShader.getShader();
	}

	onUpdate(delta: number): void {
	}
}
