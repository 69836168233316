<svg class='small-4' fill='none' viewBox='0 0 136 23' xmlns='http://www.w3.org/2000/svg'>
	<path d='M127.5 5v14' stroke='#20A1E3' stroke-linecap='round' stroke-width='7'
				style='stroke:#20a1e3;stroke:color(display-p3 .1255 .6314 .8902);stroke-opacity:1' />
	<path d='M6 12c21.997-.537 101.53-5.5 123.5-5.5' stroke='url(#paint0_linear_1079_1699)' stroke-linecap='round'
				stroke-width='12' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1079_1699' x1='2.1' x2='151.919' y1='15.528'
										y2='9.445'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .small-4 {
    width: 136px;
    height: 23px;
    position: absolute;
    top: 40px;
    left: calc(50% - 136px / 2);
  }
</style>
