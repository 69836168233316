<script lang='ts'>
	import Close from '@/lib/icons/Close.svelte';
	import { popup } from '@/stores';
	import { fade } from 'svelte/transition';
	import Image from '@/lib/Image.svelte';
	import Overlay from '@/lib/Overlay.svelte';

	let popupMode;
	$:popupMode = $popup.data.popupMode ? `-${$popup.data.popupMode}` : '';
</script>

<Overlay on:click={popup.hide} />
<div class='popup {popupMode}' transition:fade={{duration: 100}}>
	<div class='header'>
		<div class='title'>{$popup.data.year}</div>
		<Close on:click={()=>popup.hide()} />
	</div>
	<div class='content'>
		{#if $popup.data.cover}
			<Image class='-popup' img='{{
				name: $popup.data.cover,
				ext: "png"
			}}' alt={$popup.data.title} />
		{/if}
		<div class='title'>{$popup.data.title}</div>
		<div class='text'>{@html $popup.data.text}</div>

		<!--{#if $popup.data.footnotes}-->
		<!--	<div class='footnotes'>-->
		<!--		<hr>-->
		<!--		{$popup.data.footnotes}-->
		<!--	</div>-->
		<!--{/if}-->
	</div>
</div>

<style lang='scss'>
  .popup {
    --color: #000;
    --header-color: #000;
    --bg-color: #FFFFFF;

    width: 100%;
    max-height: calc(100% - 16px);
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    background: var(--bg-color);
    color: var(--color);
    box-sizing: border-box;
    pointer-events: auto;
    overflow: hidden;
    z-index: 10;

    @include breakpoint-up($tablet) {
      width: 375px;
      height: fit-content;
      max-height: calc(100% - 32px);
      margin: auto;
      top: 16px;
      bottom: 16px;
      left: 0;
      right: 0;
      border-radius: 20px;
    }

    &:after {
      height: 46px;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, var(--bg-color) 50%, rgba(255, 255, 255, 0) 100%);
      content: '';
    }

    > .header {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--header-color);

      > .title {
        font-family: $TTHoves, sans-serif;
        font-weight: 900;
        font-size: 52px;
        line-height: 60px;
      }

      > :global(svg) {
        flex-shrink: 0;
      }
    }

    > .content {
      padding-bottom: 30px;
      font-size: 16px;
      line-height: 24px;
      white-space: pre-line;
      overflow-y: auto;
      @include scrollbar-styles;

      > .title {
        margin-top: 32px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: $TTHoves, sans-serif;
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
      }

      > .text {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 32px;

        :global(a) {
          @include reset-link;
          color: inherit;
          text-underline-offset: 4px;

          &:hover {
            opacity: 0.7;
          }

          &:after {
            content: ' ↗';
          }
        }
      }

      > .footnotes {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        color: var(--color);

        hr {
          margin: 0 0 24px;
          background: none;
          border: none;
          border-top: 1px solid var(--color);
        }
      }

      :global(img) {
        width: 100%;
      }
    }

    &.-blackout {
      --color: #FFFFFF;
      --header-color: #FFFFFF;
      --bg-color: #353535;
    }

    &.-sugar {
      --color: #FFFFFF;
      --header-color: #FFFFFF;
      --bg-color: #CC99CC;
    }

    &.-white-header {
      --header-color: #FFF;
    }

    &.-donut {
      --bg-color: #BFECEC;
    }

    &.-meme {
      --bg-color: #FFDF38;
    }

    &.-black {
      --header-color: #FFF;
      --color: #FFF;
      --bg-color: #000;
    }
  }
</style>
