<svg on:click class='close' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 44 44'>
	<path stroke='currentColor' stroke-width='6' d='m8 8 28 28m0-28L8 36' />
</svg>

<style lang='scss'>
  .close {
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
</style>
