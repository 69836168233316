import CustomLoader from '@pkg/scene/partials/loader';
import CustomSound from '@pkg/sound-manager/custom-sound';
import DJTurntable from '@pkg/sound-manager/DJTurntable';
import { get } from 'svelte/store';
import { music } from '@/stores';
import { AudioContextFactory, Sound } from 'excalibur';
import { sound } from '@pkg/sound-manager/res';

type SoundScenePath<T, K = keyof T, B = keyof T[K]> = `${K}.${B}`

type SceneList = {
	[key: string]: Scene
}
type Scene = {
	[key: string]: CustomSound
}

export abstract class SoundManager<T extends SceneList> {
	audioContext = AudioContextFactory.create();
	activeDj: DJTurntable;
	muted = false;
	private dj: DJTurntable[] = [];

	protected constructor(private scenes: T) {
		document.addEventListener('visibilitychange', async () => {
			const isHidden = document.visibilityState === 'hidden';

			await this.suspend(isHidden);

			if (!isHidden) this.activeDj?.play();
		});
	}

	preload(scene: keyof T) {
		return new CustomLoader([...Object.values(this.scenes[scene])]).load();
	}

	get(path: SoundScenePath<T>): CustomSound {
		const { scene, sound } = this.parsePath(path);

		return (<CustomSound>this.scenes[scene][sound]);
	}

	duration(path: SoundScenePath<T>) {
		const { scene, sound } = this.parsePath(path);

		return (<CustomSound>this.scenes[scene][sound]).duration;
	}

	djCrossfeed(track1: DJTurntable, track2: DJTurntable, ratio = 0) {
		track1.volume = 1 - ratio;
		track2.volume = ratio;
	}

	createDJTurntable(path: SoundScenePath<T>) {
		const { scene, sound } = this.parsePath(path);
		const dj = new DJTurntable(this.audioContext, this.scenes[scene][sound], !get(music));
		this.dj.push(dj);

		return dj;
	}

	stop() {
		for (let djTurntable of this.dj) {
			djTurntable.pause();
		}
	}

	mute(val: boolean) {
		this.muted = val;

		for (let djTurntable of this.dj) {
			djTurntable.mute(val);
		}

		for (let sounds of Object.values(sound.final)) {
			(<Sound>sounds).volume = val ? 0 : 1;
		}
	}

	suspend(state: boolean) {
		return state ? this.audioContext.suspend() : this.audioContext.resume();
	}

	filter(val: boolean) {
		for (let djTurntable of this.dj) {
			djTurntable.filter(val);
		}
	}

	protected parsePath(path: SoundScenePath<T>) {
		const [scene, sound] = path.split('.');

		return {
			scene,
			sound,
		};
	}
}
