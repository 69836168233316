<script lang='ts'>
	import { fade } from 'svelte/transition';
</script>

<div class='overlay' on:click transition:fade={{duration: 100}}></div>

<style lang='scss'>
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.4;
    pointer-events: auto;
  }
</style>
