<script lang='ts'>
	import { fade } from 'svelte/transition';
</script>

<div class='curtain' transition:fade></div>

<style lang='scss'>
  .curtain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
  }
</style>
