<svg class='small-2' fill='none' viewBox='0 0 157 37' xmlns='http://www.w3.org/2000/svg'>
	<path d='M131.5 9.517v24.25' stroke='#1EA9E2' stroke-linecap='round' stroke-width='6'
				style='stroke:#1ea9e2;stroke:color(display-p3 .1176 .6627 .8863);stroke-opacity:1' />
	<path d='M20 10.267v6.5' stroke='#18D3DE' stroke-linecap='round' stroke-width='6'
				style='stroke:#18d3de;stroke:color(display-p3 .0941 .8275 .8706);stroke-opacity:1' />
	<path d='M6 6.794c9.684-.61 19.567-.691 29.333-.761C79.098 5.717 107.413 7.759 151 9.267'
				stroke='url(#paint0_linear_1077_1681)' stroke-linecap='round'
				stroke-width='12' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1077_1681' x1='1.421' x2='176.484' y1='11.362'
										y2='-2.691'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .small-2 {
    width: 157px;
    height: 37px;
    position: absolute;
    top: 40px;
    left: calc(50% - 157px / 2);
  }
</style>
