import MobileDetect from 'mobile-detect';

export const preload = (src: string) => {
	return new Promise(function(resolve) {
		let img = new Image();
		img.onload = resolve;
		img.src = src;
	});
};

export class IPhoneDetect {
	static detect() {
		if (!window) return false;

		const md = new MobileDetect(window.navigator.userAgent);

		return md.is('iPhone');
	}
}

export const assetsUrl = (url) => {
	return `${import.meta.env.VITE_ASSETS_URL}${url}`;
};
