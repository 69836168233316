import { Actor, Animation, Vector } from 'excalibur';
import SpriteSheetAnimation from '@pkg/scene/partials/spritesheet-animation';
import { player } from '@pkg/scene/res';
import AnimationsList from '@pkg/scene/partials/animations-list';

export type PlayerAnim = {
	walk1: Animation;
	walk2: Animation;
	walk3: Animation;
	walk4: Animation;
	walk5: Animation;
}

class PlayerAnimation extends AnimationsList<PlayerAnim> {
	constructor() {
		super(player.walk);
	}

	protected getList(): any {
		const sheetAnimation = new SpriteSheetAnimation([player.walk], 1000 / 60);

		return {
			walk1: <Animation>sheetAnimation.getAnimation('walk/1'),
			walk2: <Animation>sheetAnimation.getAnimation('walk/2'),
			walk3: <Animation>sheetAnimation.getAnimation('walk/3'),
			walk4: <Animation>sheetAnimation.getAnimation('walk/4'),
			walk5: <Animation>sheetAnimation.getAnimation('walk/5'),
		};
	}
}

export default class Player extends Actor {
	currentAnimName: 'walk1' | 'walk2' | 'walk3' | 'walk4' | 'walk5';
	private readonly animations = new PlayerAnimation();
	private readonly currentAnim!: Animation;

	constructor() {
		super({
			width: 25,
			height: 25,
			pos: new Vector(0, -150),
			anchor: new Vector(0.5, 1),
			z: 199,
		});

		this.setAnimation('walk1');

		this.on('exitviewport', () => this.kill());
	}

	get direction() {
		return this.currentAnim.direction;
	}

	reverse() {
		this.currentAnim.reverse();
	}

	pause() {
		this.currentAnim.pause();
	}

	stop() {
		this.currentAnim.pause();
		this.currentAnim.reset();
	}

	play(timeScale: number) {
		this.currentAnim.timeScale = timeScale;
		!this.currentAnim.isPlaying && this.currentAnim.play();
	}

	setAnimation(name: keyof PlayerAnim) {
		this.currentAnimName = name;
		this.currentAnim = this.animations.getAnimation(name);
		this.graphics.use(this.currentAnim);
	}
}
