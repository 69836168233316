<div class='scroll'></div>

<style lang='scss'>
  .scroll {
    width: calc(460px / 2);
    height: calc(170px / 2);
    position: absolute;
    top: calc(-170px / 2);
    background-image: url("/static/2x/swipe.gif");
    background-size: cover;
  }
</style>
