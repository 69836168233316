<svg class='paint-big' fill='none' viewBox='0 0 338 81' xmlns='http://www.w3.org/2000/svg'>
	<path d='M246.298 15.746v18.287' stroke='#1CB2E2' stroke-linecap='round' stroke-width='8'
				style='stroke:#1cb2e2;stroke:color(display-p3 .1098 .698 .8863);stroke-opacity:1' />
	<path d='M259.904 15.746v61.115' stroke='#1DB0E2' stroke-linecap='round' stroke-width='8'
				style='stroke:#1db0e2;stroke:color(display-p3 .1137 .6902 .8863);stroke-opacity:1' />
	<path
		d='M9.005 23.27c30.967-3.989 158.937-10.713 190.141-11.843 30.209-1.094 60.509-1.867 90.737-1.878 10.58-.003 21.139-.444 31.719-.448l6.533-.002c1.411 0-.282-.264-.801-.437'
		stroke='url(#paint0_linear_1077_1670)' stroke-linecap='round'
		stroke-width='17' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1077_1670' x1='-1.088' x2='386.742' y1='32.574'
										y2='16.973'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .paint-big {
    width: 338px;
    height: 81px;
    position: absolute;
    top: 110px;
    left: calc(50% - 338px / 2);
  }
</style>
