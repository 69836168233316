import {
	Actor,
	Animation,
	AnimationDirection,
	AnimationStrategy,
	Axis,
	BoundingBox,
	CollisionType,
	Color,
	Engine,
	Keys,
	ParallaxComponent,
	Scene,
	ScreenElement,
	Trigger,
	Vector,
} from 'excalibur';
import { events, player, scene } from './res';
import sm from '@pkg/sound-manager/index';
import sceneController from '@pkg/scene/controller';
import controller from '@pkg/scene/controller';
import { easeInSine } from '@pkg/scene/utils';
import sceneData from '@/assets/scene.json';
import Player from '@pkg/scene/components/player';
import CustomLoader from '@pkg/scene/partials/loader';
import { sound } from '@pkg/sound-manager/res';
import { GrayScalePostProcessor } from '@pkg/scene/partials/postprocessors';
import SpriteSheetAnimation from '@pkg/scene/partials/spritesheet-animation';
import { buildings, BUILDINGS_LAYER_PARALLAX, EPOCH } from '@pkg/scene/enums';
import CustomTrigger from '@pkg/scene/partials/custom-trigger';
import EpochEventFactory from '@pkg/scene/partials/epoch-event-factory';
import { config } from '@pkg/scene/config';

export default class MainScene extends Scene {
	public loaders = {
		scene1970: new CustomLoader([
			...Object.values(scene),
			events.epoch1970.static,
			player.walk,
			sound.scene1970.track,
			...Object.values(sound.final),
		]),
		scene1980: new CustomLoader([
			events.epoch1980.static,
			sound.scene1980.track,
		]),
		scene1990: new CustomLoader([
			events.epoch1990.static,
			sound.scene1990.track,
		]),
		scene2000: new CustomLoader([
			...Object.values(events.epoch2000),
			sound.scene2000.track,
		]),
		scene2010: new CustomLoader([
			...Object.values(events.epoch2010),
			sound.scene2010.track,
		]),
	};
	public player!: Player;
	protected cullingItems: Actor[] = [];
	private isTouched = false;
	private isPressed = false;
	private touchStartScreenPos!: Vector;
	private startTime!: DOMHighResTimeStamp;
	private pressedStartTime!: DOMHighResTimeStamp;
	private dj = {
		scene1970: null,
		scene1980: null,
		scene1990: null,
		scene2000: null,
		scene2010: null,
	};
	private sceneSpeed = 0;
	private musicSpeed = 0;
	private sceneDirection!: number;
	private grayScalePostProcessor = new GrayScalePostProcessor();
	private readonly sceneEventsLayerGroup = sceneData.layers.find(({ name }) => name === 'events');
	private canInput: boolean = true;
	private finalAnimation: Animation;

	async onInitialize() {
		this.engine.backgroundColor = Color.fromHex('#546E94');
		this.camera.pos = new Vector(0, -672).scaleEqual(0.75);
		this.addBg();
		this.addPlayer();
		this.addEpoch1970Events();
		this.addTriggers();
		this.camera.strategy.lockToActorAxis(this.player, Axis.X);

		this.engine.graphicsContext.addPostProcessor(this.grayScalePostProcessor);
		this.grayScalePostProcessor.value = 1;

		await this.loaders.scene1980.load().then(() => this.addEpoch1980Events());
		await this.loaders.scene1990.load().then(() => this.addEpoch1990Events());
		await this.loaders.scene2000.load().then(() => this.addEpoch2000Events());
		await this.loaders.scene2010.load().then(() => this.addEpoch2010Events());
		this.addFinalTriggers();
	}

	onActivate() {
		this.engine.input.pointers.primary.on('down', (e) => {
			this.isTouched = true;
			this.touchStartScreenPos = e.screenPos;
		});

		this.engine.input.pointers.primary.on('up', (e) => {
			this.isTouched = false;
			this.touchStartScreenPos = e.screenPos;
		});

		this.engine.input.keyboard.on('press', (event) => {
			if ([Keys.D, Keys.A, Keys.Left, Keys.Right].includes(event.key)) {
				this.isPressed = true;
				this.pressedStartTime = performance.now();
			}
		});

		this.engine.input.keyboard.on('release', (event) => {
			if ([Keys.D, Keys.A, Keys.Left, Keys.Right].includes(event.key)) {
				this.isPressed = false;
			}
		});

		this.dj.scene1970 = sm.createDJTurntable('scene1970.track');
	}

	async load() {
		await this.loaders.scene1970.load();
	}

	addTriggers() {
		const greyScaleTrigger = new CustomTrigger({
			pos: new Vector(2900, -200),
			width: 200,
			height: 200,
			target: this.player,
			progress: (progress) => {
				this.grayScalePostProcessor.value = 1 - progress;
			},
		});

		this.add(greyScaleTrigger);
	}

	async addBg() {
		const bgGradientSprite = scene.buildings.getFrameSprite('bg-gradient');
		const bgGradient = new ScreenElement({
			pos: new Vector(292 - controller.drawWidth / 2, 0),
			anchor: Vector.Zero,
			z: -6,
		});
		bgGradientSprite.scale.x = controller.drawWidth;
		bgGradient.graphics.use(bgGradientSprite);
		this.add(bgGradient);

		const bgSprite = scene.skyline.toSprite();
		bgSprite.opacity = 0.6;
		const bg = new Actor({
			pos: new Vector(-1800, -500).scaleEqual(0.75),
			anchor: new Vector(0, 1),
			z: -5,
		});
		bg.addComponent(new ParallaxComponent(new Vector(0.5, 1)));

		for (let i = 0; i < Math.ceil(21000 / bgSprite.width); i++) {
			bg.graphics.layers.create({
				name: 'layer#' + i,
				order: i,
				offset: new Vector(i * bgSprite.width, 0),
			}).use(bgSprite);
		}

		this.addToCulling(bg);

		const buildingsLayersGroup = sceneData.layers.find(({ name }) => name === 'buildings');

		for (let [ind, layer] of buildingsLayersGroup.layers.entries()) {
			const layerParallax = BUILDINGS_LAYER_PARALLAX[layer.name];

			for (let [index, building] of layer.objects.entries()) {
				const { x, y, width, gid } = building;
				const [spriteName, flip] = buildings[gid].split('_');
				if (!spriteName) continue;

				const sprite = scene.buildings.getFrameSprite(`${spriteName}`);
				const isSidewalk = spriteName === 'sidewalk';

				sprite.flipHorizontal = !!flip;

				const actor = new Actor({
					name: spriteName,
					pos: new Vector(x, y).scaleEqual(0.75),
					anchor: new Vector(0, 1),
					z: parseInt(`${ind}`.padEnd(3, '0')) + index,
					collisionType: CollisionType.PreventCollision,
				});

				if (<number>layerParallax !== 1) {
					actor.addComponent(new ParallaxComponent(new Vector(<number>layerParallax, 1)));
				}

				if (!isSidewalk) {
					actor.graphics.use(sprite);
				} else {
					for (let i = 0; i < Math.ceil(width / sprite.width); i++) {
						actor.graphics.layers.create({
							name: 'layer#' + i,
							order: i,
							offset: new Vector(i * sprite.width, 0),
						}).use(sprite);
					}
				}

				this.addToCulling(actor);
			}
		}

		await new CustomLoader([
			scene.fpAnim,
			// ...Object.values(sound.fx),
		]).load();

		const spriteSheetAnimations = new SpriteSheetAnimation([scene.fpAnim], 1000 / 8);
		const barrelAnim = spriteSheetAnimations.getAnimation('front-plan-animations/fg-flaming-barrel');
		const garbageAnim = spriteSheetAnimations.getAnimation('front-plan-animations/fg-grabage_can');

		const fgFlamingBarrel = this.cullingItems.find(actor => actor.name === 'fg-flaming-barrel');
		const fgGarbageCan = this.cullingItems.find(actor => actor.name === 'fg-grabage can');
		// const phonebooth = this.cullingItems.find(actor => actor.name === 'phonebooth-foreground');
		// const newstand = this.cullingItems.find(actor => actor.name === 'fg-newstand');

		fgFlamingBarrel.graphics.use(barrelAnim);
		fgGarbageCan.graphics.use(garbageAnim);

		// EpochEventFactory.addForegroundSoundFx(fgFlamingBarrel, barrelAnim, sound.fx.barrel);
		// EpochEventFactory.addForegroundSoundFx(fgGarbageCan, garbageAnim, sound.fx.flies);
		// EpochEventFactory.addForegroundSoundFx(phonebooth, scene.buildings.getFrameSprite(`phonebooth-foreground`), sound.fx.phone);
		// EpochEventFactory.addForegroundSoundFx(newstand, scene.buildings.getFrameSprite(`fg-newstand`), sound.fx.newspaper);

		barrelAnim.play();
		garbageAnim.play();
	}

	addPlayer() {
		this.player = new Player();
		this.add(this.player);
	}

	async addEpoch1970Events() {
		const { static: staticLayer } = events.epoch1970;

		const { epochEvents } = EpochEventFactory.create(this, {
			epochEventsObject: this.sceneEventsLayerGroup.layers[0].objects,
			epochEventsGraphics: [
				staticLayer.getFrameSprite('1'),
				staticLayer.getFrameSprite('2'),
				staticLayer.getFrameSprite('3'),
				staticLayer.getFrameSprite('4'),
				staticLayer.getFrameSprite('5'),
				staticLayer.getFrameSprite('6'),
			],
		});

		const bombattaHead = (<Actor>epochEvents[5]);
		bombattaHead.z = -1;
		bombattaHead.addComponent(new ParallaxComponent(new Vector(0.8, 1)));

		for (let epochEvent of epochEvents) this.addToCulling(epochEvent);

		if (!config.useAnimation) return;

		await new CustomLoader([
			events.epoch1970.birthday,
			events.epoch1970.bambatta,
			events.epoch1970.bambatta1,
			events.epoch1970.bambatta2,
		]).load();

		const spriteSheetAnimations = new SpriteSheetAnimation([events.epoch1970.birthday, events.epoch1970.bambatta, events.epoch1970.bambatta1, events.epoch1970.bambatta2]);

		EpochEventFactory.replaceAnimation(epochEvents, [
			spriteSheetAnimations.getAnimation('1'),
		]);
	}

	async addEpoch1980Events() {
		this.dj.scene1980 = sm.createDJTurntable('scene1980.track');
		const { static: staticLayer } = events.epoch1980;

		const { transitionTrigger, epochEvents } = EpochEventFactory.create(this, {
				epochEventsObject: this.sceneEventsLayerGroup.layers[1].objects,
				epochEventsGraphics: [
					staticLayer.getFrameSprite('1'),
					staticLayer.getFrameSprite('2'),
					staticLayer.getFrameSprite('3'),
					staticLayer.getFrameSprite('4'),
					staticLayer.getFrameSprite('5'),
					staticLayer.getFrameSprite('6'),
				],
			},
			{
				pos: new Vector(8612, -200),
				track1: this.dj.scene1970,
				track2: this.dj.scene1980,
				dress1: 'walk1',
				dress2: 'walk2',
			});

		this.add(transitionTrigger);
		for (let epochEvent of epochEvents) this.addToCulling(epochEvent);

		if (!config.useAnimation) return;

		await new CustomLoader([
			events.epoch1980.car,
			events.epoch1980.car1,
			events.epoch1980.car2,
			events.epoch1980.car3,
		]).load();

		const spriteSheetAnimations = new SpriteSheetAnimation([
			events.epoch1980.car,
			events.epoch1980.car1,
			events.epoch1980.car2,
			events.epoch1980.car3,
		]);

		EpochEventFactory.replaceAnimation(epochEvents, [
			null,
			null,
			null,
			null,
			spriteSheetAnimations.getAnimation('5'),
			null,
		]);
	}

	async addEpoch1990Events() {
		this.dj.scene1990 = sm.createDJTurntable('scene1990.track');
		const { static: staticLayer } = events.epoch1990;

		const { epochEvents, transitionTrigger } = EpochEventFactory.create(this, {
			epochEventsObject: this.sceneEventsLayerGroup.layers[2].objects,
			epochEventsGraphics: [
				staticLayer.getFrameSprite('1'),
				staticLayer.getFrameSprite('2'),
				staticLayer.getFrameSprite('3'),
				staticLayer.getFrameSprite('4'),
				staticLayer.getFrameSprite('5'),
			],
		}, {
			pos: new Vector(16015, -200),
			track1: this.dj.scene1980,
			track2: this.dj.scene1990,
			dress1: 'walk2',
			dress2: 'walk3',
		});

		epochEvents[3].addComponent(new ParallaxComponent(new Vector(BUILDINGS_LAYER_PARALLAX.back, 1)));

		epochEvents[4].z = 250;
		epochEvents[4].addComponent(new ParallaxComponent(new Vector(BUILDINGS_LAYER_PARALLAX.front, 1)));

		this.add(transitionTrigger);
		for (let epochEvent of epochEvents) this.addToCulling(epochEvent);

		if (!config.useAnimation) return;

		await new CustomLoader([
			events.epoch1990.cops1,
			events.epoch1990.cops2,
			events.epoch1990.cops3,
			events.epoch1990.cops4,
			events.epoch1990.cops5,
		]).load();

		const spriteSheetAnimations = new SpriteSheetAnimation([
			events.epoch1990.cops1,
			events.epoch1990.cops2,
			events.epoch1990.cops3,
			events.epoch1990.cops4,
			events.epoch1990.cops5,
		]);

		EpochEventFactory.replaceAnimation(epochEvents, [
			null,
			null,
			null,
			spriteSheetAnimations.getAnimation('graffiti', {
				strategy: AnimationStrategy.Freeze,
			}),
			spriteSheetAnimations.getAnimation('cops'),
		]);
	}

	addEpoch2000Events() {
		this.dj.scene2000 = sm.createDJTurntable('scene2000.track');
		const { static: staticLayer } = events.epoch2000;
		const { epochEvents, transitionTrigger } = EpochEventFactory.create(this, {
			epochEventsObject: this.sceneEventsLayerGroup.layers[3].objects,
			epochEventsGraphics: [
				staticLayer.getFrameSprite('1'),
				staticLayer.getFrameSprite('2'),
				staticLayer.getFrameSprite('3'),
				staticLayer.getFrameSprite('4'),
				staticLayer.getFrameSprite('5'),
			],
		}, {
			pos: new Vector(23065, -200),
			track1: this.dj.scene1990,
			track2: this.dj.scene2000,
			dress1: 'walk3',
			dress2: 'walk4',
		});

		this.add(transitionTrigger);
		for (let epochEvent of epochEvents) this.addToCulling(epochEvent);
	}

	async addEpoch2010Events() {
		this.dj.scene2010 = sm.createDJTurntable('scene2010.track');
		const { static: staticLayer } = events.epoch2010;
		const { epochEvents, transitionTrigger } = EpochEventFactory.create(this, {
			epochEventsObject: this.sceneEventsLayerGroup.layers[4].objects,
			epochEventsGraphics: [
				staticLayer.getFrameSprite('1'),
				staticLayer.getFrameSprite('2'),
				staticLayer.getFrameSprite('3'),
				staticLayer.getFrameSprite('4'),
				staticLayer.getFrameSprite('5'),
				staticLayer.getFrameSprite('6'),
				staticLayer.getFrameSprite('7'),
				staticLayer.getFrameSprite('8'),
			],
		}, {
			pos: new Vector(30200, -200),
			track1: this.dj.scene2000,
			track2: this.dj.scene2010,
			dress1: 'walk4',
			dress2: 'walk5',
		});

		this.add(transitionTrigger);

		epochEvents[6].z = 200;

		for (let epochEvent of epochEvents) this.addToCulling(epochEvent);

		await new CustomLoader([
			events.epoch2010.final,
			events.epoch2010.final1,
		]).load();

		const spriteSheetAnimation = new SpriteSheetAnimation([events.epoch2010.final, events.epoch2010.final1]);

		this.finalAnimation = spriteSheetAnimation.getAnimation('6', {
			strategy: AnimationStrategy.Freeze,
		});

		EpochEventFactory.replaceAnimation(epochEvents, [
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			this.finalAnimation,
		]);

		this.finalAnimation.pause();
	}

	playSound() {
		this.startTime = performance.now();
		sm.activeDj = this.dj.scene1970;
		sm.activeDj.play();
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		const progress = ((performance.now() - this.startTime) / 1000) % sm.activeDj?.duration || 1;
		const playerOffset = this.player.pos.x;

		controller.browser.document.nativeComponent.documentElement.style.setProperty('--scene-offset', `${Math.floor(playerOffset)}px`);

		if (!this.touchStartScreenPos) return;

		if (this.canInput) {
			if (this.isTouched || this.isPressed) {
				let musicSpeed = 0;

				if (this.isTouched) {
					const startOffset = this.touchStartScreenPos.x - this.engine.input.pointers.primary.lastScreenPos.x;
					musicSpeed = Math.max(Math.min(startOffset / (Math.min(sceneController.halfDrawWidth, 300)), 1), -1);
				}

				if (this.isPressed) {
					const duration = performance.now() - this.pressedStartTime;
					const speedDirection = (this.engine.input.keyboard.isHeld(Keys.Left) || this.engine.input.keyboard.isHeld(Keys.A)) ? -1 : 1;

					musicSpeed = Math.min(duration / 800, 1) * speedDirection;
				}

				this.musicSpeed = musicSpeed;
				this.sceneSpeed = this.musicSpeed;
				this.sceneDirection = this.musicSpeed >= 0 ? 1 : -1;

				if (this.sceneDirection !== (this.player.direction === AnimationDirection.Forward ? 1 : -1)) {
					this.player.reverse();
				}

				this.player.play(easeInSine(Math.abs(this.sceneSpeed)));
			} else {
				this.musicSpeed = 1;
				this.sceneSpeed = 0;
				this.sceneDirection = 1;
				this.player.pause();
			}
		}

		this.player.vel.x = easeInSine(Math.abs(this.sceneSpeed)) * config.maxSpeed * this.sceneDirection;
		sm.activeDj?.turn(easeInSine(Math.abs(this.musicSpeed)), this.sceneDirection === -1, progress);

		this.player.pos.x = Math.max(this.player.pos.x, -650);
	}

	onPreUpdate(_engine: Engine, _delta: number) {
		super.onPreUpdate(_engine, _delta);

		const { viewport } = this.camera;

		for (const item of this.cullingItems) {
			this.culling(viewport, item);
		}
	}

	addToCulling(en: Actor) {
		en.active = false;
		this.cullingItems.push(en);
	}

	culling(viewport: BoundingBox, en: Actor) {
		let bounds = en.graphics.localBounds;
		const parallaxComponent = en.get(ParallaxComponent);

		if (parallaxComponent) {
			const oneMinusFactor = Vector.One.sub(parallaxComponent.parallaxFactor);
			let parallaxOffset = this.camera.pos.scale(oneMinusFactor);

			bounds = bounds.translate(parallaxOffset);
		}

		const transformedBounds = bounds.transform(en.transform.get().matrix);
		const graphicsOffscreen = !this.engine.screen.getWorldBounds().overlaps(transformedBounds);

		if (graphicsOffscreen && en.active) this.remove(en);
		if (!graphicsOffscreen && !en.active) this.add(en);
	}

	goToEpoch(epoch: EPOCH) {
		sm.stop();

		this.player.pos.x = (() => {
			switch (epoch) {
				case EPOCH.E70:
					this.player.setAnimation('walk1');
					sm.activeDj = this.dj.scene1970;
					return 0;

				case EPOCH.E80:
					this.player.setAnimation('walk2');
					sm.activeDj = this.dj.scene1980;
					return 8888;

				case EPOCH.E90:
					this.player.setAnimation('walk3');
					sm.activeDj = this.dj.scene1990;
					return 16426;

				case EPOCH.E00:
					this.player.setAnimation('walk4');
					sm.activeDj = this.dj.scene2000;
					return 23742;

				case EPOCH.E10:
					this.player.setAnimation('walk5');
					sm.activeDj = this.dj.scene2010;
					return 30646;

				default:
					return 0;
			}
		})();

		this.grayScalePostProcessor.value = epoch === EPOCH.E70 ? 1 : 0;

		sm.activeDj.volume = 1;
		sm.activeDj.play();
	}

	getEpoch() {
		return this.player.currentAnimName;
	}

	private moveToFinal() {
		this.canInput = false;
		this.sceneSpeed = 1;
		this.musicSpeed = 1;
	}

	private addFinalTriggers() {
		const arcTrigger = new Trigger({
			pos: new Vector(38118, -200),
			width: 100,
			height: 500,
			target: this.player,
			action: () => {
				sound.final.party.loop = true;
				sound.final.party.play();
				sm.stop();
				sm.activeDj = null;
				this.moveToFinal();
			},
		});

		this.add(arcTrigger);

		const finalTrigger = new Trigger({
			pos: new Vector(40200, -200),
			width: 100,
			height: 500,
			target: this.player,
			action: async () => {
				this.camera.clearAllStrategies();
				this.finalAnimation && this.finalAnimation.play();
				sound.final.applause.play();
				await controller.waitFor(3000);
				this.engine.events.emit('final');
			},
		});

		this.add(arcTrigger);
		this.add(finalTrigger);
	}
}
