import { Color, DisplayMode, Engine, Input } from 'excalibur';
import MainScene from './main-scene';
import { Pane } from 'tweakpane';
import Bugsnag from '@bugsnag/js';
import { config } from '@pkg/scene/config';
import { EPOCH } from '@pkg/scene/enums';

class SceneController extends Engine {
	constructor() {
		super({
			displayMode: DisplayMode.FitScreenAndFill,
			viewport: {
				width: 390,
				height: 672,
			},
			resolution: {
				width: 390 * 1.5,
				height: 672 * 1.5,
			},
			pixelRatio: 1,
			maxFps: 60,
			configurePerformanceCanvas2DFallback: {
				allow: true,
				threshold: {
					fps: 20,
					numberOfFrames: 100,
				},
			},
			pointerScope: Input.PointerScope.Canvas,
			backgroundColor: Color.Black,
			suppressConsoleBootMessage: true,
		});

		this.pauseAudioWhenHidden = true;
		this.canvas.style.cursor = 'grab';

		this.setScreenHeightRatio();
		this.addScene('main', new MainScene());
		config.showFps && this.showFpsCounter();
		config.debug && this.activateDebug();

		window.addEventListener('resize', this.setScreenHeightRatio.bind(this));
		Bugsnag.start({ apiKey: '4ef9c62cb779fd3c687e5dea09919ec6' });
	}

	setScreenHeightRatio() {
		this.browser.document.nativeComponent.documentElement.style.setProperty('--screen-height-ratio', `${window.innerHeight / 672}`);
	}

	getSceneLoaders() {
		return (<MainScene>this.scenes['main']).loaders;
	}

	async start() {
		await super.start();
		// await this.prepareScene();
		// this.addStartButton();
	}

	playSound() {
		(<MainScene>this.scenes['main']).playSound();
	}

	addStartButton() {
		const { body } = document;

		body.insertAdjacentHTML(
			'beforeend',
			`
        <div id='overlay' style='position:fixed;bottom: 0;right: 0;top: 0;left: 0;display: flex;justify-content: center;align-items: center;background-color:rgba(0,0,0,0.5);'>
            <button id='start' style='font-size: 24px;'>START!</button>
        </div>
    `,
		);
		// @ts-ignore
		body.querySelector('#start').addEventListener('click', () => {
			body.querySelector('#overlay')?.remove();
			this.goToScene('main');
		});
	}

	async prepareScene() {
		await (<MainScene>this.scenes['main']).load();
	}

	waitFor(time: number): Promise<void> {
		return new Promise(res => {
			this.clock.schedule(() => res(), time);
		});
	}

	teleportToEpoch(epoch: EPOCH) {
		(<MainScene>this.scenes['main']).goToEpoch(epoch);
	}

	getEpoch() {
		return (<MainScene>this.scenes['main']).getEpoch();
	}

	private showFpsCounter() {
		const fpsPane = new Pane();
		fpsPane.addMonitor(this.clock.fpsSampler, 'fps', {
			view: 'graph',
		});
	}

	private activateDebug() {
		this.debug.entity.showId = false;
		this.debug.collider.showGeometry = true;
		this.debug.transform.showPosition = true;

		this.showDebug(true);
	}
}

const sceneController = new SceneController();

export default sceneController;
