import { Sound } from 'excalibur';
import { assetsUrl } from '@/utils';

export const sound = {
	scene1970: {
		track: new Sound(assetsUrl('assets/epoch70/track.mp3')),
	},
	scene1980: {
		track: new Sound(assetsUrl('assets/epoch80/track.mp3')),
	},
	scene1990: {
		track: new Sound(assetsUrl('assets/epoch90/track.mp3')),
	},
	scene2000: {
		track: new Sound(assetsUrl('assets/epoch00/track.mp3')),
	},
	scene2010: {
		track: new Sound(assetsUrl('assets/epoch10/track.mp3')),
	},
	final: {
		applause: new Sound(assetsUrl('assets/sfx/applause.mp3')),
		party: new Sound(assetsUrl('assets/sfx/party.mp3')),
		vinyl: new Sound(assetsUrl('assets/sfx/vinyl.mp3')),
	},
	fx: {
		flies: new Sound(assetsUrl('assets/sfx/flies.mp3')),
		barrel: new Sound(assetsUrl('assets/sfx/barrel fire.mp3')),
		phone: new Sound(assetsUrl('assets/sfx/phone.mp3')),
		newspaper: new Sound(assetsUrl('assets/sfx/newspaper.mp3')),
	},
};
