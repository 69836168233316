<script>
	import Button from '@/lib/Button.svelte';

	export let buttonText = 'Далее';
	export let next;
</script>

<div class='screen'>
	<div class='container -tiny'>
		<div class='content'>
			<slot></slot>
		</div>
		<Button on:click={next}>{buttonText}</Button>
	</div>
</div>

<style lang='scss'>
  .screen {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
			align-content: stretch;
    }

    .content {
      position: absolute;
      top: 35.4%;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      :global(.caption) {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
</style>
