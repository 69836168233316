<div class='groznov-link'>Сделано в <a href='https://groznov.co/' target='_blank'>GROZNOV®</a></div>

<style lang='scss'>
  .groznov-link {
    margin-top: 36px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;

    a {
      @include reset-link;
    }
  }
</style>
