<button on:click>
	<slot />
</button>

<style lang='scss'>
  button {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    border: none;
    color: #FFFFFF;
    border-radius: 30px;
    background: linear-gradient(89.9deg, #17D8DD -3.27%, #1AB7E1 65.59%, #2394E3 118.02%);
    cursor: pointer;
  }
</style>
