<div class='scroll'></div>

<style lang='scss'>
  .scroll {
    width: calc(200px / 2);
    height: calc(170px / 2);
    position: absolute;
    top: calc(-170px / 2);
    background-image: url("/static/2x/sound.gif");
    background-size: cover;
    z-index: -1;
  }
</style>
