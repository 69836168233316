<svg class='crown' fill='none' viewBox='0 0 103 57' xmlns='http://www.w3.org/2000/svg'>
	<path
		d='M6 42.952c1.003-9.524 9.78-20.192 14.778-28 1.717-2.684 2.468-4.225 4.278-.833 1.866 3.5 3.665 6.993 5.722 10.389 4.578 7.56 3.42 6.416 8.333-.667 3.519-5.073 7.44-9.89 11.278-14.722 2.506-3.156 3.88-4.913 5.667-.334.96 2.463 9.385 26.308 11 25.5 2.073-1.036 3.89-3.932 5.388-5.555A77.983 77.983 0 0 1 85.89 17.119c.519-.353 7.077-5.177 7.889-4.5 1.01.841.211 6.14.278 7.333.232 4.188.084 10.28 1.944 14'
		stroke='url(#paint0_linear_1079_1696)' stroke-linecap='round'
		stroke-width='12' />
	<path d='M6 44.952v9' stroke='#18D8DE' stroke-linecap='round' stroke-width='5'
				style='stroke:#18d8de;stroke:color(display-p3 .0941 .8471 .8706);stroke-opacity:1' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1079_1696' x1='3.158' x2='112.516' y1='66.657'
										y2='66.175'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .crown {
    width: 103px;
    height: 57px;
    position: absolute;
    top: -50px;
    left: calc(50% - 103px / 2);
  }
</style>
