import type { IEventLayerObject } from '@pkg/scene/types';
import { Actor, Animation, CollisionType, Sprite, Vector } from 'excalibur';

export default class EventsLayer {
	static getEvents(objects: IEventLayerObject[], graphics: (Sprite | Animation)[]) {
		const events = [];

		for (let [index, object] of objects.entries()) {
			const sprite = graphics[index];
			const { x, y } = object;

			if (!sprite) continue;

			const actor = new Actor({
				pos: new Vector(x, y).scaleEqual(0.75),
				anchor: new Vector(0.5, 1),
				z: 198,
				collisionType: CollisionType.PreventCollision,
			});

			events.push(actor);
			actor.graphics.use(sprite);

			// if (sprite instanceof Animation) (<Animation>sprite).play();
		}

		return events;
	}
}
