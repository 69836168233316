<div class='click'></div>

<style lang='scss'>
  .click {
    width: calc(349px / 2);
    height: calc(180px / 2);
    position: absolute;
    top: calc(-180px / 2);
    background-image: url("/static/2x/click.gif");
    background-size: cover;
    z-index: -1;
  }
</style>
