import EventsLayer from '@pkg/scene/components/events-layer';
import { Actor, Animation, Sound, Sprite, Vector } from 'excalibur';
import CustomTrigger, { TriggerXSide } from '@pkg/scene/partials/custom-trigger';
import soundManager from '@pkg/sound-manager';
import sm from '@pkg/sound-manager';
import MainScene from '@pkg/scene/main-scene';
import DJTurntable from '@pkg/sound-manager/DJTurntable';
import type { IEventLayerObject } from '@pkg/scene/types';
import type { PlayerAnim } from '@pkg/scene/components/player';
import controller from '@pkg/scene/controller';

export default class EpochEventFactory {
	static create(scene: MainScene, epochEventsConfig: {
		epochEventsObject: IEventLayerObject, epochEventsGraphics: []
	}, transitionTriggerConfig?: {
		pos: Vector, track1: DJTurntable, track2: DJTurntable, dress1: keyof PlayerAnim, dress2: keyof PlayerAnim
	}) {
		const epochEvents = EventsLayer.getEvents(epochEventsConfig.epochEventsObject, epochEventsConfig.epochEventsGraphics);

		const transitionTrigger = transitionTriggerConfig ? new CustomTrigger({
			pos: transitionTriggerConfig.pos,
			width: 100,
			height: 200,
			target: scene.player,
			progress: (progress) => {
				soundManager.djCrossfeed(transitionTriggerConfig.track1, transitionTriggerConfig.track2, progress);
			},
			onStart: async (side) => {
				if (side === TriggerXSide.LEFT) {
					transitionTriggerConfig.track2.volume = 0;
					transitionTriggerConfig.track2.play();
					scene.player.setAnimation(transitionTriggerConfig.dress2);
				} else {
					transitionTriggerConfig.track1.volume = 0;
					transitionTriggerConfig.track1.play();
					scene.player.setAnimation(transitionTriggerConfig.dress1);
				}
			},
			onEnd: (side) => {
				if (side === TriggerXSide.RIGHT) {
					transitionTriggerConfig.track1.volume = 0;
					transitionTriggerConfig.track1.pause();
					soundManager.activeDj = transitionTriggerConfig.track2;
				} else {
					transitionTriggerConfig.track2.volume = 0;
					transitionTriggerConfig.track2.pause();
					soundManager.activeDj = transitionTriggerConfig.track1;
				}
			},
		}) : null;

		return {
			transitionTrigger,
			epochEvents,
		};
	}

	static replaceAnimation(events: (Actor | null)[], animationList: Animation[]) {
		for (let [ind, event] of events.entries()) {
			if (!event) continue;

			const anim = animationList[ind];

			if (anim) {
				anim.play();
				event.graphics.use(anim);
			}
		}
	}

	static addForegroundSoundFx(actor: Actor, sprite: Sprite | Animation, sound: Sound) {
		sound.loop = true;

		actor.on('preupdate', () => {
			const actorXPos = sprite.width / 2 + actor.pos.x / 1.1;
			const cameraXCenter = controller.screen.getWorldBounds().center.x;
			const cameraXRight = controller.screen.getWorldBounds().right;
			const halfCameraWidth = Math.abs(cameraXCenter - cameraXRight);
			let volume = 0;

			if (!sm.muted) {
				if (cameraXCenter > actorXPos) {
					volume = 1 + Math.max((actorXPos - cameraXCenter) / halfCameraWidth, -1);
				} else {
					volume = 1 - Math.min((actorXPos - cameraXCenter) / halfCameraWidth, 1);
				}
			}

			sound.volume = Math.floor(volume * 100) / 100;
		});

		actor.on('initialize', () => sound.play(0));

		actor.on('enterviewport', () => sound.volume = 0);
		actor.on('enterviewport', () => sound.volume = 0);
	}
}
