<svg class='circle' fill='none' viewBox='0 0 91 45' xmlns='http://www.w3.org/2000/svg'>
	<path d='M80.298 12.246v28.49' stroke='#20A3E3' stroke-linecap='round' stroke-width='6'
				style='stroke:#20a3e3;stroke:color(display-p3 .1255 .6392 .8902);stroke-opacity:1' />
	<path
		d='M20.093 22.024c-2.714 1.42-12.34 4.716-12.036 9.13.272 3.943 7.553 4.076 10.15 4.415 15.974 2.084 34.673-1.005 49.728-6.527 4.803-1.762 13.219-5.277 14.187-11.206.88-5.496-7.105-9.069-15.3-9.279-17.946-2.269-38.245 2.732-51.064 8.826'
		stroke='url(#paint0_linear_1077_1674)' stroke-linecap='round'
		stroke-width='16' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1077_1674' x1='5.709' x2='95.793' y1='54.328'
										y2='53.9'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .circle {
    width: 91px;
    height: 45px;
    position: absolute;
    top: -30px;
    left: 65px;
  }
</style>
