<svg class='small-1' fill='none' viewBox='0 0 147 37' xmlns='http://www.w3.org/2000/svg'>
	<path d='M105.732 8.978v7.685' stroke='#1CB2E2' stroke-linecap='round' stroke-width='3'
				style='stroke:#1cb2e2;stroke:color(display-p3 .1098 .698 .8863);stroke-opacity:1' />
	<path d='M111.45 8.978v25.685' stroke='#1DB0E2' stroke-linecap='round' stroke-width='3'
				style='stroke:#1db0e2;stroke:color(display-p3 .1137 .6902 .8863);stroke-opacity:1' />
	<path
		d='M6.002 12.14C19.017 10.463 72.8 7.637 85.915 7.162c12.696-.46 25.431-.784 38.135-.789 4.446-.001 8.884-.186 13.331-.188h2.746c.592-.001-.119-.112-.337-.184'
		stroke='url(#paint0_linear_1077_1677)' stroke-linecap='round'
		stroke-width='12' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1077_1677' x1='1.76' x2='164.758' y1='16.05'
										y2='9.494'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .small-1 {
    width: 147px;
    height: 37px;
    position: absolute;
    top: 40px;
    left: calc(50% - 147px / 2);
  }
</style>
