<script lang='ts'>
	import OnBoardingScreen from '@/lib/onboarding/OnBoardingScreen.svelte';
	import { blackCurtain, currentScreen, startWalkPose } from '@/stores';
	import Click from '@/lib/icons/Click.svelte';
	import MainScene from '@/lib/screens/MainScene.svelte';
	import controller from '@pkg/scene/controller';
	import sm from '@pkg/sound-manager';

	const next = async () => {
		$currentScreen = MainScene;

		await controller.waitFor(600);
		sm.get('final.vinyl').stop();
		controller.playSound();
		blackCurtain.hide();
		startWalkPose.hide();
	};
</script>

<OnBoardingScreen buttonText='Начать путешествие' {next}>
	<Click />
	<div class='caption'>
		Кликайте на даты и изучайте <br> факты
	</div>
</OnBoardingScreen>
