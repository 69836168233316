<script lang='ts'>
	import Cookie from 'js-cookie';
	import { fade } from 'svelte/transition';
	import Popup from '@/lib/Popup.svelte';
	import { onMount } from 'svelte';
	import controller from '@pkg/scene/controller';
	import { blackCurtain, cookiesAccept, currentScreen, menu, popup, startWalkPose } from '@/stores';
	import Curtain from '@/lib/Curtain.svelte';
	import StartWalkPose from '@/lib/StartWalkPose.svelte';
	import Menu from '@/lib/Menu.svelte';
	import FinalScreen from '@/lib/screens/FinalScreen.svelte';
	import CustomHead from '@/lib/CustomHead.svelte';
	import CookiePopup from '@/lib/CookiePopup.svelte';

	let viewportComponent;

	const updateViewportComponent = () => {
		viewportComponent = $currentScreen;
	};

	onMount(() => {
		updateViewportComponent();
		controller.start();
		controller.events.once('final', () => {
			blackCurtain.show();
			$currentScreen = FinalScreen;
		});

		// $cookiesAccept = !Cookie.get('hh50-cookies');
	});
</script>

<CustomHead />

<main>
	{#if $blackCurtain}
		<Curtain />
	{/if}

	{#if $currentScreen === viewportComponent}
		<div on:outroend={updateViewportComponent} transition:fade={{duration: 600}}>
			<svelte:component this={viewportComponent} />
		</div>
	{/if}

	{#if $popup.active}
		<Popup />
	{/if}

	{#if $menu}
		<Menu />
	{/if}

	{#if $startWalkPose}
		<StartWalkPose />
	{/if}

	<!--{#if $cookiesAccept}-->
<!--		<CookiePopup />-->
<!--	{/if}-->
</main>

<style lang='scss'>
  main {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }
</style>
