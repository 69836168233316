<script lang='ts'>
	import { fade } from 'svelte/transition';
	import PlaylistLink from '@/lib/PlaylistLink.svelte';
	import GroznovLink from '@/lib/GroznovLink.svelte';
	import Overlay from '@/lib/Overlay.svelte';
	import { menu, popup } from '@/stores';
	import { EPOCH } from '@pkg/scene/enums';
	import controller from '@pkg/scene/controller';
	import { onMount } from 'svelte';

	const moveTo = (epoch: EPOCH) => {
		menu.hide();
		popup.hide();
		controller.teleportToEpoch(epoch);
	};

	let path;

	onMount(() => {
		path = controller.getEpoch();
	});
</script>

<Overlay on:click={menu.hide} />
<div class='menu' transition:fade={{duration: 100}}>
	<ol>
		<li class:-active={path==='walk1'}>
			<button on:click={()=>moveTo(EPOCH.E70)}>1973—1983</button>
		</li>
		<li class:-active={path==='walk2'}>
			<button on:click={()=>moveTo(EPOCH.E80)}>1984—1990</button>
		</li>
		<li class:-active={path==='walk3'}>
			<button on:click={()=>moveTo(EPOCH.E90)}>1991—1998</button>
		</li>
		<li class:-active={path==='walk4'}>
			<button on:click={()=>moveTo(EPOCH.E00)}>1999—2009</button>
		</li>
		<li class:-active={path==='walk5'}>
			<button on:click={()=>moveTo(EPOCH.E10)}>2010—2023</button>
		</li>
	</ol>
	<div class='footer'>
		<PlaylistLink />
		<GroznovLink />
	</div>
</div>

<style lang='scss'>
  .menu {
    width: 100%;
    height: 100%;
    padding: 56px 20px 20px;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background: #000000;
    color: #FFFFFF;
    box-sizing: border-box;
    pointer-events: auto;
    overflow: hidden;
    z-index: 5;

    @include breakpoint-up($tablet) {
      width: 375px;
      padding-top: 72px;
    }

    ol {
      margin: auto 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      font-family: $TTHoves, sans-serif;
      font-weight: 900;
      font-size: 36px;
      line-height: 60px;
      list-style-type: none;
      counter-reset: num;

      li {
        position: relative;
        opacity: 0.4;

        &:before {
          position: absolute;
          content: counter(num);
          counter-increment: num;
        }

        button {
          @include reset-button;
          margin-left: 50px;
        }

        &.-active {
          opacity: 1;
          pointer-events: none;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
    }
  }
</style>
