<script lang='ts'>
	import EventsOverlay from '@/lib/EventsOverlay.svelte';
	import Header from '@/lib/Header.svelte';
</script>

<div class='main-scene'>
	<Header />
	<EventsOverlay />
</div>

<style lang='scss'>

</style>
