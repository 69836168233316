export const buildings = {
	16: 'alley',
	17: 'basketball',
	18: 'bridge',
	19: 'dumpster',
	20: 'garage',
	21: 'house',
	22: 'sidewalk',
	23: 'rabica',
	24: 'sidewalk',
	25: 'wall',
	26: 'wall',
	27: 'wall',
	28: 'bridge2',
	29: 'fg-advertising',
	30: 'fg-flaming-barrel',
	31: 'fg-grabage can',
	32: 'fg-hydrant',
	33: 'fg-newstand',
	34: 'phonebooth-foreground',
	35: 'wall for legends',
	2147483666: 'bridge_flip',
	2147483676: 'bridge2_flip',
};

export enum BUILDINGS_LAYER_PARALLAX {
	front = 1.1,
	middle = 1,
	back = 0.9
}

export enum EPOCH {
	E70,
	E80,
	E90,
	E00,
	E10,
}
