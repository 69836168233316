<svg class='small-5' fill='none' viewBox='0 0 147 27' xmlns='http://www.w3.org/2000/svg'>
	<path d='M16 10.3v13.5M25 5v12' stroke='#18D2DE' stroke-linecap='round' stroke-width='6'
				style='stroke:#18d2de;stroke:color(display-p3 .0941 .8235 .8706);stroke-opacity:1' />
	<path d='M6 9.102c44.96-3.31 89.48-.458 134.5-2.602' stroke='url(#paint0_linear_1104_1880)' stroke-linecap='round'
				stroke-width='12' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1104_1880' x1='1.752' x2='163.769' y1='10.77'
										y2='-4.377'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .small-5 {
    width: 147px;
    height: 27px;
    position: absolute;
    top: 40px;
    left: calc(50% - 147px / 2);
  }
</style>
