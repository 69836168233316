export default [
	{
		year: 1973,
		caption: '11 августа',
		title: 'Рождение хип-хопа',
		cover: '/popup/img_1_01',
		text: '11 августа 1973 года в Нью-Йорке выдалось поистине жарким, но не только из-за знойной погоды. В одном из жилых комплексов Бронкса DJ Kool Herc устроил вечеринку в честь своей маленькой сестрёнки. \n' +
			'\n' +
			'Во время выступления он начал проигрывать с пластинок соло ударных из песен, которые называют брейками, и миксовать их между собой. Вдруг его друг Coke La Rock схватил микрофон и начал «читать» свои строки поверх получившегося бита. \n' +
			'\n' +
			'Зрители были в восторге, но даже не догадывались, что стали свидетелями зарождения нового жанра.',
		mode: '-big -circle',
		footnotes: 'Диджей Кул Херк\n' +
			'Коук Ла Рок',
	},
	{
		year: 1977,
		title: 'Блэкаут в Нью-Йорке \n' +
			'с 13 на 14 июля\n',
		cover: '/popup/img_1_02',
		text: 'Молниеносному развитию хип-хопа способствовали самые настоящие молнии, которые в ночь с 13 на 14 июля 1977 года обесточили Нью-Йорк на целые сутки.\n' +
			'\n' +
			'Легенда гласит, что сложившаяся в городе ситуация позволила будущим деятелям хип-хопа под покровом ночи раздобыть необходимую аппаратуру, которую они не могли себе позволить.\n',
		popupMode: 'blackout',
		mode: '-small1',
	},
	{
		year: 1977,
		title: 'Rock Steady Crew',
		cover: '/popup/img_1_03',
		text: 'Диджеи продолжали миксовать брейки на вечеринках, а молодые люди продолжали танцевать. Они вставали в круг на танцполе и демонстрировали друг другу свои умения в сплаве различных танцевальных стилей и акробатики — так появился брейк-данс и первая команда Rock Steady Crew.\n' +
			'\n' +
			' Именно им предстояло стать самой уважаемой командой танцоров и популяризаторами всего жанра хип-хоп.\n',
		mode: '-small2',
		footnotes: 'Рок Стеди Крю',
	},
	{
		year: 1979,
		title: 'Sugar Hill Gang ' +
			'\n' +
			'и первый успех',
		cover: '/popup/img_1_04',
		text: 'Настоящим прорывом для хип-хопа оказалась жутко заразительная композиция <a href="https://open.zvuk.com/cLQ0/mfzajz32" target="_blank">Rappers’ Delight</a> от Sugar Hill Gang. Песня звучала очень эффектно благодаря искусности сессионных музыкантов и басовой партии, которую они «позаимствовали» у главного хита лета «Good Times» диско-группы Chic.\n',
		popupMode: 'sugar',
		mode: '-small3',
		footnotes: '«Рэпперс Дилайт» от Шугахилл Гэнг\n' +
			'«Гуд Таймс» диско-группы Чик',
	},
	{
		year: 1980,
		title: 'Roland TR-808 ' +
			'\n' +
			'и новый  звук',
		cover: '/popup/img_1_05',
		text: 'Новый звук пришёл в хип-хоп вместе с драм-машиной Roland TR-808, чтобы изменить всё. Причиной успеха стали бракованные транзисторы, которые исказили звук настоящих ударных. Roland TR-808 подарил миру неисчислимое количество культовых хитов. Вы слышите, как он звучит? Вот прямо сейчас?\n',
		mode: '-small5',
		footnotes: 'Роланд ТР-808',
	},
	{
		year: 1982,
		title: 'Afrika Bambaataa ' +
			'\n' +
			'выпускает «Planet ' +
			'\n' +
			'Rock»',
		cover: '/popup/img_1_06',
		text: 'Вооружившись легендарной 808-й не менее легендарный <a href="https://open.zvuk.com/cLQ0/44lmn07s" target="_blank">Afrika Bambaata</a>, лидер и идеолог хип-хоп объединения Zulu Nation, выпустил концептуальный сингл «Planet Rock». \n' +
			'\n' +
			'Трек с космической перкуссией и электронными звуками сразу же начал греметь на танцполах США и Европы. Он объединил танцевальную музыку, нью-йоркский рэп и уличную культуру воедино, и это потрясло музыкальный мир.\n',
		mode: '-small4 -crown',
		popupMode: 'white-header',
		footnotes: 'Африка Бамбаата\n' +
			'Зулу Нэйшн\n' +
			'«Плэнет Рок»',
	},
	{
		year: 1984,
		title: 'Основание лейбла ' +
			'\n' +
			'Def Jam',
		cover: '/popup/img_2_01',
		text: 'В маленькой комнатушке Нью-Йоркского общежития зародился самый влиятельный хип-хоп лейбл в истории — Def Jam. Продюсеры Рик Рубин и Рассел Симмонс объединили усилия, чтобы на долгие годы стать главными поставщиками хитов в чарты всего мира. \n' +
			'\n' +
			'Начало было положено молодым артистом <a href="https://open.zvuk.com/cLQ0/143pu029" target="_blank">LL Cool J</a> и его успешным синглом «I Need a Beat». \n',
		mode: '-small5',
		footnotes: 'Деф Джэм\n' +
			'ЛЛ Кул Джей\n' +
			'«Ай Нид э Бит»',
	},
	{
		year: 1985,
		title: 'Появление A Tribe ' +
			'\n' +
			'Called Quest',
		cover: '/popup/img_2_02',
		text: 'Хип-Хоп может быть другим. Так решили парни из Квинса и образовали группу <a href="https://open.zvuk.com/cLQ0/jpe06675" target="_blank">A Tribe Called Quest</a>. Они соединили ритмы хип-хопа с мелодичностью джаза, добавили пронзительные тексты на социальные темы и получили уникальное звучание, которое оказало влияние на многих хип-хоп и R&B музыкантов в будущем.',
		mode: '-small2',
		footnotes: 'Э Трайб Колд Квест\n' +
			'Р’н’Би',
	},
	{
		year: 1986,
		title: 'Beastie Boys ' +
			'\n' +
			'возглавляют чарты',
		cover: '/popup/img_2_03',
		text: 'Первым рэп альбомом, который возглавил музыкальный чарт Billboard-200 был «Licensed to Ill» от белых парней из Бруклина <a href="https://open.zvuk.com/cLQ0/30v3vvxw" target="_blank">Beastie Boys</a>. \n' +
			'\n' +
			'Дебютная пластинка главных хулиганов жанра была насквозь пропитана юмором и провокацией, а кричащая смесь рэпа, хэви-метала и панка стала фирменным стилем группы, которая покорила не одно поколение слушателей. \n',
		mode: '-small1',
		popupMode: 'white-header',
		footnotes: 'Биллбоард-200\n' +
			'«Лицензд ту Илл»\n' +
			'Бисти Бойс',
	},
	{
		year: 1987,
		title: 'Public Enemy — ' +
			'\n' +
			'голос поколения',
		cover: '/popup/img_2_04',
		text: '<a href="https://open.zvuk.com/cLQ0/2qn2v9if" target="_blank">Public Enemy</a> изменили образ, звук и посыл хип-хопа, когда выпустили свой первый альбом «Yo! Bum Rush The Show». Плотный и свирепый звук, сильная политическая позиция и искусные поэтические рифмы навсегда переписали правила рэпа.',
		mode: '-small2',
		footnotes: 'Паблик Энеми\n' +
			'«Йо! Бам Раш Зе Шоу»',
	},
	{
		year: 1988,
		title: 'N.W.A. и расцвет ' +
			'\n' +
			'гангста рэпа',
		cover: '/popup/img_2_05',
		text: 'Гангста рэп широкой поступью начал шагать по миру.\n' +
			'Группа <a href="https://open.zvuk.com/cLQ0/jvjykzj9" target="_blank">N.W.A.</a> выпустила свой дебютный альбом "Straight Outta Compton" и вписала Лос-Анджелес в историю хип-хопа. \n' +
			'\n' +
			'Откровенные тексты на запрещённые темы, проблемы с полицией и отсутствие эфиров на радио не помешали альбому стать платиновым, а коллективу утвердиться в статусе голоса улиц.\n',
		mode: '-small1',
		footnotes: 'Н.В.А.\n' +
			'«Стрэйт Аутта Комптон»',
	},
	{
		year: 1991,
		title: 'Парни с кипарисовых ' +
			'\n' +
			'холмов',
		cover: '/popup/img_3_01',
		text: 'Первая латиноамериканская хип-хоп группа <a href="https://open.zvuk.com/cLQ0/9873hdgp" target="_blank">Cypress Hill</a> с одноимённым дебютным альбомом врывается в чарты. Неподражаемый вокал лидера коллектива <a href="https://open.zvuk.com/cLQ0/swhox8xv" target="_blank">B-Real</a> в сочетании с психоделическим роком стали главным рецептом успеха коллектива на годы вперёд.',
		mode: '-small5',
		footnotes: 'Сайпресс Хилл\n' +
			'Би-Рил',
	},
	{
		year: 1992,
		title: 'Доктора вызывали?',
		cover: '/popup/img_3_02',
		text: '<a href="https://open.zvuk.com/cLQ0/0mw6hdaa" target="_blank">Dr.Dre</a> определил звучание хип-хопа западного побережья на годы вперёд, когда выпустил свой дебютный сольный альбом «The Chronic».\n' +
			'\n' +
			'Он умело собрал воедино G-Funk, гангстерскую, но расслабленную лирику и виртуозные биты. А еще открыл миру исполнителя <a href="https://open.zvuk.com/cLQ0/tko67vjh" target="_blank">Snoop Dogg</a>, который годом позже выпустил легендарный альбом «Doggystyle».\n',
		mode: '-small2',
		footnotes: 'Доктор Дре\n' +
			'«Зе Кроник»\n' +
			'Джи-Фанк\n' +
			'Снуп Догг\n' +
			'«Доггистайл»',
	},
	{
		year: 1993,
		title: 'Wu-Tang, 9 артистов ' +
			'\n' +
			'как целый клан',
		cover: '/popup/img_3_03',
		text: 'Весь мир наблюдал за возрождением рэпа восточного побережья, когда вышел дебютный альбом <a href="https://open.zvuk.com/cLQ0/lm599iiz" target="_blank">Wu-Tang Clan</a>. \n' +
			'\n' +
			'Пластинка «36 Chambers» потрясла фанатов жанра своим жёстким звучанием, а также изобретательными и ироничными рифмами девяти участников коллектива. С тех пор запись считается одним из самых значимых альбомов 90-х, а также одним из величайших хип-хоп альбомов всех времён. \n',
		mode: '-small3',
		footnotes: ' Ву-Тэнг Клэн\n' +
			'«36 Чемберс»',
	},
	{
		year: 1994,
		title: 'Biggie и Nas\nЗолотая эра ' +
			'\n' +
			'хип-хопа',
		cover: '/popup/img_3_04',
		text: 'Наступил золотой год для хип-хопа, вышли сразу два классических альбома, которые по праву заняли почётное место в истории жанра.\n' +
			' \n' +
			'<a href="https://open.zvuk.com/cLQ0/5raz0tbc" target="_blank">Nas</a> выпустил свой легендарный «Illmatic», а <a href="https://open.zvuk.com/cLQ0/xmuxaeyy" target="_blank">Notorious B.I.G.</a> представил миру «Ready To Die». Умение рассказывать истории, игра слов и их впечатляющий запас рифм отлично сочетались с непревзойдённой музыкой и личным обаянием артистов.\n',
		mode: '-small4',
		popupMode: 'white-header',
		footnotes: 'Нас\n' +
			'«Иллматик»\n' +
			'Ноториоус Биг\n' +
			'«Реди Ту Дай»',
	},
	{
		year: 1996,
		caption: '13 сентября',
		title: 'Выстрелы в Лас-Вегасе',
		cover: '/popup/img_3_06',
		text: 'Противостояние хип-хоп артистов западного и восточного побережий привело к трагической развязке. 7 сентября главный поэт рэп музыки, политический активист и актёр <a href="https://open.zvuk.com/cLQ0/l4oja7s7" target="_blank">2Pac</a> был тяжело ранен в Лас-Вегасе. 13 сентября он скончался в реанимации. \n',
		mode: '-big',
		footnotes: 'ТуПак',
	},
	{
		year: 1997,
		caption: '9 марта',
		title: 'Выстрелы в Лос-Анджелесе',
		cover: '/popup/img_3_05',
		text: 'Спустя полгода рано утром в Лос-Анджелесе был застрелен второй участник конфликта — <a href="https://open.zvuk.com/cLQ0/xmuxaeyy" target="_blank">Notorious BIG</a>. Это положило конец противоборству побережий.',
		mode: '-big',
		footnotes: 'Ноториоус Биг',
	},
	{
		year: 1999,
		title: 'Eminem врывается ' +
			'\n' +
			'в рэп',
		cover: '/popup/img_4_01',
		text: 'Белый парень из Детройта вытащил баттл-рэп промышленных районов на большую сцену. Весь мир узнал об <a href="https://open.zvuk.com/cLQ0/5x0mkdpt" target="_blank">Eminem</a> и его альтер-эго Slim Shady в дебютном «The Slim Shady LP». \n' +
			'\n' +
			'Его очаровательное и бескомпромиссное безумие напугало одних и восхитило других, но никто не остался равнодушным. Альбом имел огромный коммерческий успех и положительные отзывы критиков.\n',
		mode: '-small1',
		footnotes: 'Эминем\n' +
			'Слим Шейди\n' +
			'«Зе Слим Шейди ЛП»',
	},
	{
		year: 1999,
		title: 'Герой без лица',
		cover: '/popup/img_4_02',
		text: 'Герой независимого хип-хопа не носил плащ, но носил металлическую маску. \n' +
			'<a href="https://open.zvuk.com/cLQ0/u59pqj0c" target="_blank">MF DOOM</a> с первым альбомом «Operation: Doomsday» сделал андеграунд рэп популярным по всему миру.\n' +
			'\n' +
			'Это был авангардный коллаж различных жанров музыки под винтажные барабанные брейки, абстрактная лирика, обрывки старых мультфильмов — всё это в стиле лоу-фай. Подобное звучание и анонимность автора позволило пластинке достичь мифического статуса в жанре.\n',
		mode: '-small2',
		footnotes: 'МФ ДУМ\n' +
			'«Оперейшн: Думсдэй»',
	},
	{
		year: 2002,
		title: '50 cent — артист ' +
			'\n' +
			'на миллион',
		cover: '/popup/img_4_03',
		text: 'Гангста-рэп вернулся и стал новой поп-музыкой. \n' +
			'Причиной был человек, ныне известный даже за пределами жанра благодаря своему хиту In Da Club. Артист на миллион продаж в первую неделю — <a href="https://open.zvuk.com/cLQ0/kkor8gst" target="_blank">50 Cent</a>. \n' +
			'\n' +
			'«Get Rich Or Die Tryin» — первая пластинка рэпера имела небывалый коммерческий успех. Уличные истории, рассказанные на высшем уровне с помощью продюсеров Eminem и Dre, захватили чарты по всему миру.\n',
		mode: '-small1',
		footnotes: '«Ин Да Клаб»\n' +
			'Фифти Сент\n' +
			'«Гет Рич Ор Дай Трайн»\n' +
			'Эминем\n' +
			'Дре',
	},
	{
		year: 2005,
		title: 'Время трэпа',
		cover: '/popup/img_4_04',
		text: 'Новый звук хип-хопа пришёл с юга. \n' +
			'Атланта и Новый Орлеан подарили миру новый поджанр — трэп, который характеризуется минимальным набором звуков и крайне запрещённой лирикой. \n' +
			'\n' +
			'Грохот басов, сложный ритмический рисунок ударных и тексты о жизни опасных районов покорили слушателей на альбоме «Trap House» одного из пионеров жанра <a href="https://open.zvuk.com/cLQ0/t9b5jajo" target="_blank">Gucci Mane</a>. \n' +
			'Пластинка открыла трэпу дорогу в мейнстрим и определила движение хип-хопа на следующее десятилетие.\n',
		mode: '-small2',
		footnotes: '«Трэп Хаус»\n' +
			'Гуччи Мэйн',
	},
	{
		year: 2006,
		title: 'J Dilla меняет жизни',
		cover: '/popup/img_4_05',
		text: 'Год когда весь мир понял, что рэп без речитатива может быть таким же интересным. \n' +
			'Легенда инструментального хип-хоп <a href="https://open.zvuk.com/cLQ0/z85k8p27" target="_blank">J Dilla</a> выпустил альбом «Donuts», который сразу же стал классикой. Запись, которая повлияла на большинство современных артистов, была умелым сочетанием музыкального культурного наследия Америки и виртуозных битов.\n',
		mode: '-small3',
		popupMode: 'donut',
		footnotes: 'Джей Дилла\n' +
			'«Донатс»',
	},
	{
		year: 2010,
		title: 'Drake, начало' +
			'\n' +
			' самого большого ' +
			'\n' +
			'успеха',
		cover: '/popup/img_5_01',
		text: 'Пока мир хип-хоп музыки продолжали завоевывать трэп, грязное звучание и криминальные истории, 25-летний канадец пошёл по другому пути. \n' +
			'\n' +
			'Мелодичное пение и умение неординарно подавать свой рэп на мягких и приятных битах в стиле R&B сразу выделили новичка <a href="https://open.zvuk.com/cLQ0/woq5j0lq" target="_blank">Drake</a> на фоне остальных.\n' +
			'Его дебютный альбом “Thank Me Later” напомнил хип-хопу, каково это быть нежным. Пластинка моментально стала лидером Billboard, а что было дальше, вы, итак, знаете…\n',
		mode: '-small4',
		popupMode: 'meme',
		footnotes: 'Р’н’Би\n' +
			'Дрэйк\n' +
			'«Сэнк Ми Лейтер»\n' +
			'Биллбоард',
	},
	{
		year: 2013,
		title: 'Пришествие Yeezus',
		cover: '/popup/img_5_02',
		text: '<a href="https://open.zvuk.com/cLQ0/gzyhupo4" target="_blank">Kanye West</a> — талантливый и успешный артист, который как аранжировщик подарил миру легендарные альбомы <a href="https://open.zvuk.com/cLQ0/2osmqynv" target="_blank">Jay Z</a> и <a href="https://open.zvuk.com/cLQ0/3h19eray" target="_blank">Common</a>, и поразил критиков своими работами, как самостоятельный исполнитель.\n' +
			'\n' +
			' Он не раз привносил что-то новое в жанр, но в этому году он поменял не только хип-хоп, но и весь музыкальный мир. После выхода альбома Yeezus все хотели звучать как Kanye: громко, электронно, странно и бескомпромиссно. \n',
		mode: '-small5',
		popupMode: 'black',
		footnotes: 'Канье Уэст\n' +
			'Джей Зи\n' +
			'Коммон\n' +
			'«Изус»',
	},
	{
		year: 2018,
		title: 'Пулитцеровская ' +
			'\n' +
			'премия за хип-хоп',
		cover: '/popup/img_5_03',
		text: 'Хип-хоп признали на высшем уровне благодаря главному поэту современного рэпа.\n' +
			'<a href="https://open.zvuk.com/cLQ0/4od4z0pd" target="_blank">Kendrick Lamar</a> стал первым рэп артистом, который получил Пулитцеровскую премию в области музыки за свой альбом «Damn».\n' +
			'\n' +
			'Комитет премии отметил, что несмотря на кажущиеся отличия текстов артиста от традиционной поэзии, тексты Ламара – это всё-таки стихи. \n',
		mode: '-small1',
		footnotes: 'Кендрик Ламар\n' +
			'«Дэмн»',
	},
	{
		year: 2018,
		title: 'Триумф женского' +
			'\n' +
			' рэпа',
		cover: '/popup/img_5_04',
		text: 'Первые места в чартах, награды престижных музыкальных премий, миллиарды просмотров и даже рекорды Гиннесса – эти две персоны вывели женский рэп на совершенно новый уровень. \n' +
			'\n' +
			'<a href="https://open.zvuk.com/cLQ0/q563vpak" target="_blank">Nicki Minaj</a> прокладывала этот путь к успеху своим неординарным талантом в хип-хопе, а <a href="https://open.zvuk.com/cLQ0/mkzwuizr" target="_blank">Cardi B</a> и её дебютный альбом поставили жирную точку в спорах об успешности женского рэпа.\n',
		mode: '-small2',
		footnotes: 'Ники Минаж\n' +
			'Карди Би',
	},
	{
		year: 2019,
		title: 'Drill & Pop Smoke',
		cover: '/popup/img_5_05',
		text: 'Для парней, которые живут в преступных районах, был лишь один способ рассказать без прикрас о том, что они видят на улицах каждый день — это дрилл. Жанр, который зародился в Чикаго, расцвёл на улицах Лондона и раскачал весь мир из Нью-Йорка является прямым продолжателем традиций ганста-рэпа и трэпа. Мрачные тексты песен обычно отражают уличную жизнь и, как правило, жестоки и неудобно реалистичны. \n' +
			'\n' +
			'<a href="https://open.zvuk.com/cLQ0/46yw9ym4" target="_blank">Pop Smoke</a> — не просто человек, благодаря которому дрилл становится мейнстримом, это легенда жанра, к сожалению, которая ушла слишком рано.\n',
		mode: '-small3',
		popupMode: 'white-header',
		footnotes: 'Поп Смоук',
	},
];
