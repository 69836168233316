<svg class='left-arrow' fill='none' viewBox='0 0 51 41' xmlns='http://www.w3.org/2000/svg'>
	<path
		d='M.377 34.212a.75.75 0 0 0 0 1.06l4.773 4.773a.75.75 0 1 0 1.061-1.06l-4.243-4.243 4.243-4.243a.75.75 0 1 0-1.06-1.06L.376 34.212ZM48.702 0c0 9.556-3.432 18.012-11.053 24.097-7.643 6.104-19.589 9.895-36.741 9.895v1.5c17.347 0 29.673-3.83 37.677-10.222C46.612 18.86 50.202 9.944 50.202 0h-1.5Z'
		fill='#6B6B6B' />
</svg>

<style lang='scss'>
  .left-arrow {
    width: 51px;
    height: 41px;
  }
</style>
