<svg class='big-1' fill='none' viewBox='0 0 208 49' xmlns='http://www.w3.org/2000/svg'>
	<path d='M150.264 13.218v10.886' stroke='#1CB2E2' stroke-linecap='round' stroke-width='8' />
	<path d='M162.363 8.218V44.6' stroke='#1DB0E2' stroke-linecap='round' stroke-width='8' />
	<path
		d='M9.003 17.696c18.434-2.374 94.615-6.377 113.191-7.05 17.983-.65 36.021-1.11 54.016-1.117 6.298-.002 12.584-.265 18.882-.267l3.889-.001c.84 0-.167-.157-.477-.26'
		stroke='url(#paint0_linear_1104_1870)' stroke-linecap='round'
		stroke-width='17' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1104_1870' x1='2.994' x2='233.87' y1='23.235'
										y2='13.948'>
			<stop stop-color='#17D8DD' />
			<stop offset='.568' stop-color='#1AB7E1' />
			<stop offset='1' stop-color='#2394E3' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .big-1 {
    width: 208px;
    height: 49px;
  }
</style>
