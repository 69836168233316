<svg class='music-on' fill='none' on:click viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'>
	<rect fill='#fff' height='52' rx='26' width='52' />
	<path d='M32.5 19.5s4.55 1.177 4.55 6.5-4.55 6.5-4.55 6.5' stroke='#000' stroke-linecap='square' stroke-width='2' />
	<path d='M10.4 20.429v11.143h6.5L27.3 40.3V11.7L16.9 20.43h-6.5Z' fill='#000' stroke='#000' stroke-linecap='round'
				stroke-linejoin='round' />
</svg>

<style lang='scss'>
  .music-on {
    width: 44px;
    height: 44px;

    @include breakpoint-up($tablet) {
      width: 52px;
      height: 52px;
    }
  }
</style>
