<svg class='menu-close' fill='none' on:click viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'>
	<rect fill='#fff' height='52' rx='26' width='52' />
	<path d='m17.136 15.364 19.5 19.5-1.773 1.773-19.5-19.5z' fill='#000' />
	<path d='m15.364 34.863 19.5-19.5 1.772 1.773-19.5 19.5z' fill='#000' />
</svg>

<style lang='scss'>
  .menu-close {
    width: 44px;
    height: 44px;

    @include breakpoint-up($tablet) {
      width: 52px;
      height: 52px;
    }
  }
</style>
