<script lang='ts'>
	import Sound from '@/lib/icons/Sound.svelte';
	import SilentModeCheck from '@/lib/SilentModeCheck.svelte';
	import { onMount } from 'svelte';
	import { IPhoneDetect } from '@/utils';
	import { currentScreen } from '@/stores';
	import controller from '@pkg/scene/controller';
	import OnBoardingScreen from '@/lib/onboarding/OnBoardingScreen.svelte';
	import ScrollInfo from '@/lib/onboarding/ScrollInfo.svelte';
	import sm from '@pkg/sound-manager';

	let isIPhone = false;

	const next = async () => {
		$currentScreen = ScrollInfo;
		sm.get('final.vinyl').loop = true;
		sm.get('final.vinyl').play();
	};

	onMount(() => {
		controller.goToScene('main');
		isIPhone = IPhoneDetect.detect();
	});
</script>

<OnBoardingScreen {next}>
	{#if isIPhone}
		<SilentModeCheck />
	{/if}

	<Sound />
	<div class='caption'>
		Для путешествия в историю <br> включите звук
	</div>
</OnBoardingScreen>
