<script>
	import Button from '@/lib/Button.svelte';
	import { currentScreen } from '@/stores';
	import SoundCheck from '@/lib/onboarding/SoundCheck.svelte';

	const next = () => {
		$currentScreen = SoundCheck;
	};
</script>

<div class='screen disclaimer-screen'>
	<div class='container -tiny'>
		<div class='content'>
			<div class='title'>Хип-хопу 50 лет</div>
			<div class='text'>
				<p>Такой большой, что уместить все события можно только в большую энциклопедию.</p>
				<p>Мы вспомнили основные события в истории жанра, а если кого-то забыли, обязательно вспомним на 60-летие.</p>
			</div>
		</div>
		<Button on:click={next}>Далее</Button>
	</div>
</div>

<style lang='scss'>
  .disclaimer-screen {
    padding-top: 32px;
    display: flex;

    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }

  .title {
    font-family: $TTHoves;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: #FFFFFF;
  }

  .text {
    margin-top: 24px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;

    p {
      margin: 0 0 20px;
      padding: 0;
    }
  }
</style>
