import type { TriggerOptions } from 'excalibur';
import { Actor, Trigger } from 'excalibur';

type TriggerProps = TriggerOptions & {
	onStart: (side: TriggerXSide) => void;
	onEnd: (side: TriggerXSide) => void;
	progress: (progress: number) => void;
}

export enum TriggerXSide {
	LEFT,
	RIGHT
}

export default class CustomTrigger extends Trigger {
	constructor(private props: TriggerProps) {
		super(props);

		this.registerProgressEvent();
	}

	private registerProgressEvent() {
		if (this.props.progress) {
			this.events.on('precollision', () => {
				const target = (<Actor>this.target);
				const value = Math.max(Math.min((target.pos.x - (this.pos.x - this.width / 2)) / this.width, 1), 0);

				this.props.progress(value);
			});
		}

		if (this.props.onStart) {
			this.events.on('collisionstart', () => {
				const target = (<Actor>this.target);

				this.props.onStart(target.pos.x < this.pos.x ? TriggerXSide.LEFT : TriggerXSide.RIGHT);
			});
		}

		if (this.props.onEnd) {
			this.events.on('collisionend', () => {
				const target = (<Actor>this.target);

				this.props.onEnd(target.pos.x > this.pos.x ? TriggerXSide.RIGHT : TriggerXSide.LEFT);
			});
		}
	}
}
