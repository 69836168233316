<svg class='music-off' fill='none' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
	<rect fill='#fff' height='44' rx='22' width='44' />
	<path d='M8.8 17.286v9.428h5.5l8.8 7.386V9.9l-8.8 7.386H8.8Z' fill='#000' stroke='#000' stroke-linecap='round'
				stroke-linejoin='round' />
	<path d='m26.4 17.6 7.7 7.7m0-7.7-7.7 7.7' stroke='#000' stroke-linecap='square' stroke-width='2' />
</svg>

<style lang='scss'>
  .music-off {
    width: 44px;
    height: 44px;

    @include breakpoint-up($tablet) {
      width: 52px;
      height: 52px;
    }
  }
</style>
