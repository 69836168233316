<svg class='small-3' fill='none' viewBox='0 0 147 23' xmlns='http://www.w3.org/2000/svg'>
	<path d='M128 6.3v13.5' stroke='#1FA7E2' stroke-linecap='round' stroke-width='6'
				style='stroke:#1fa7e2;stroke:color(display-p3 .1216 .6549 .8863);stroke-opacity:1' />
	<path d='M6 12.102C50.96 8.793 95.98 8.144 141 6' stroke='url(#paint0_linear_1079_1701)' stroke-linecap='round'
				stroke-width='12' />
	<defs>
		<linearGradient gradientUnits='userSpaceOnUse' id='paint0_linear_1079_1701' x1='1.736' x2='165.514' y1='16.016'
										y2='9.463'>
			<stop stop-color='#17D8DD'
						style='stop-color:#17d8dd;stop-color:color(display-p3 .0902 .8471 .8667);stop-opacity:1' />
			<stop offset='.568' stop-color='#1AB7E1'
						style='stop-color:#1ab7e1;stop-color:color(display-p3 .102 .7176 .8824);stop-opacity:1' />
			<stop offset='1' stop-color='#2394E3'
						style='stop-color:#2394e3;stop-color:color(display-p3 .1373 .5804 .8902);stop-opacity:1' />
		</linearGradient>
	</defs>
</svg>

<style lang='scss'>
  .small-3 {
    width: 147px;
    height: 23px;
    position: absolute;
    top: 40px;
    left: calc(50% - 147px / 2);
  }
</style>
