import 'normalize.css';
import './scss/app.scss';
import '@pkg/scene/controller';

import App from './App.svelte';

const app = new App({
	target: document.querySelector('body'),
});

export default app;
