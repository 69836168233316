import { sound } from './res';
import { SoundManager } from './sound-manager';

class SceneSoundManager extends SoundManager<typeof sound> {
	constructor() {
		super(sound);
	}
}

const sm = new SceneSoundManager();

export default sm;
