<script lang='ts'>
	import scene from '@/assets/scene.json';
	import texts from '@/texts';
	import Event from '@/lib/Event.svelte';
	import type { IEvent } from '@/types';

	const eventsDateLayer = scene.layers.find(({ name }) => name === 'events-dates');

	const eventsList: IEvent[] = eventsDateLayer.objects.map(({ x, y }, index) => ({
		pos: { x, y },
		data: texts[index],
	}));
</script>

<div class='events-overlay'>
	{#each eventsList as event, i}
		<Event {event} />
	{/each}
</div>

<style lang='scss'>

</style>
