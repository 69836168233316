<script>
	import MusicOn from '@/lib/icons/MusicOn.svelte';
	import { menu, music } from '@/stores';
	import MenuOpen from '@/lib/icons/MenuOpen.svelte';
	import MenuClose from '@/lib/icons/MenuClose.svelte';
	import MusicOff from '@/lib/icons/MusicOff.svelte';
</script>

<div class='header'>
	<button class='menu' on:click={menu.toggle}>
		{#if !$menu}
			<MenuOpen />
		{/if}

		{#if $menu}
			<MenuClose />
		{/if}
	</button>

	<button class='music' on:click={music.toggle}>
		{#if $music}
			<MusicOn />
		{/if}

		{#if !$music}
			<MusicOff />
		{/if}
	</button>
</div>

<style lang='scss'>
  .header {
    padding: 12px;
    position: relative;
    display: flex;
    justify-content: space-between;

    @include breakpoint-up($tablet) {
      padding: 20px;
    }

    .menu {
      @include reset-button;
      pointer-events: auto;
      z-index: 6;
    }

    .music {
      @include reset-button;
      pointer-events: auto;
      z-index: 5;
    }
  }
</style>
