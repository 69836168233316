import { Future, ImageSource, TextureLoader } from 'excalibur';
import ImageLoaderWorker from '@pkg/scene/partials/image-loader-worker?worker';

export default class CustomImageSource extends ImageSource {
	async load(): Promise<HTMLImageElement> {
		if (this.isLoaded()) {
			return this.data;
		}

		try {
			const loadedFuture = new Future<void>();
			const worker = new ImageLoaderWorker();
			let blob;
			worker.addEventListener('message', ({ data }) => {
				blob = data;
				loadedFuture.resolve(data);
			});
			worker.postMessage(this.path);

			await loadedFuture.promise;
			worker.terminate();
			const img = new Image();
			img.src = URL.createObjectURL(blob);
			img.setAttribute('data-original-src', this.path);
			this.data = img;
			TextureLoader.checkImageSizeSupportedAndLog(this.data);

		} catch (error) {
			throw `Error loading ImageSource from path '${this.path}' with error [${error.message}]`;
		}

		return this.data;
	}
}
