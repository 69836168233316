<script lang='ts'>
	import { assetsUrl } from '@/utils';

	export let img: {
		name: string;
		ext: string;
	};
	export let alt: string = '';
	export let path = 'static/';

	let className;
	export { className as class };
</script>

{#if img}
	<picture class='{className}'>
		<source srcset='{assetsUrl(`${path}${img.name}.webp`)}, {assetsUrl(`${path}2x/${img.name}.webp`)} 2x'
						type='image/webp'>
		<img srcset='{assetsUrl(`${path}2x/${img.name}.${img.ext}`)} 2x' src='{assetsUrl(`${path}${img.name}.${img.ext}`)}'
				 alt='{alt}'>
	</picture>
{/if}

<style lang='scss'>
  picture {
    display: flex;

    &.-popup {
      img {
        width: 100%;
        aspect-ratio: 840/717;
      }
    }

    &.cover {
      img {
        aspect-ratio: 474/540;
      }
    }

    &.logo {
      img {
        aspect-ratio: 433/215;
      }
    }

    &.-final {
      img {
        width: 100%;
        aspect-ratio: 1050/922;
      }
    }
  }
</style>
