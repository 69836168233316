<svg class='menu-open' fill='none' on:click viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'>
	<rect fill='#fff' height='52' rx='26' width='52' />
	<path d='M13 18.909h26v2.364H13zM13 30.727h26v2.364H13z' fill='#000' />
</svg>

<style lang='scss'>
  .menu-open {
    width: 44px;
    height: 44px;

    @include breakpoint-up($tablet) {
      width: 52px;
      height: 52px;
    }
  }
</style>
