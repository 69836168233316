<svg class='final' fill='none' viewBox='0 0 349 41' xmlns='http://www.w3.org/2000/svg'>
	<path d='M314 6v11M326 6v32' stroke='#fff' stroke-linecap='round' stroke-width='5' />
	<path
		d='M4.005 18.607C37.036 14.62 173.537 7.894 206.822 6.765c32.222-1.094 64.542-1.867 96.785-1.878 11.285-.003 22.548-.444 33.834-.448l6.968-.002c1.505 0-.3-.264-.855-.437'
		stroke='#fff' stroke-linecap='round'
		stroke-width='8' />
</svg>

<style lang='scss'>
  .final {
    width: 349px;
    height: 41px;
  }
</style>
