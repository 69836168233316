<script lang='ts'>
	import LeftArrow from '@/lib/icons/LeftArrow.svelte';
</script>

<div class='silent-mode-check'>
	<div class='text'>
		Переключите тумблер <br> бесшумного режима
	</div>
	<LeftArrow />
</div>

<style lang='scss'>
  .silent-mode-check {
    position: fixed;
    top: 40px;
    left: 20px;
    display: flex;
    flex-direction: column;

    .text {
      margin-left: 45px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #6B6B6B;
    }
  }
</style>
