<script>
	import PlaylistLink from '@/lib/PlaylistLink.svelte';
	import GroznovLink from '@/lib/GroznovLink.svelte';
	import Final from '@/lib/icons/paint/Final.svelte';
	import Image from '@/lib/Image.svelte';
	import Circle from '@/lib/icons/paint/Circle.svelte';
	import Big1 from '@/lib/icons/paint/Big1.svelte';
</script>

<div class='screen final-screen'>
	<div class='container'>

		<Image class='-final' img='{{name: "final", ext: "png"}}' />
		<div class='title'>Наши дни</div>
		<div class='caption'>
			За свои 50 лет хип-хоп из локального явления вырос в мировую культуру с миллионами почитателей по всему миру. С
			самого его зарождения главной идеей хип-хопа было объединение жанров, стилей и людей. <br><br>
			Это не просто образ мышления или стиль жизни, хип-хоп — это универсальный язык, на котором говорит весь мир.
		</div>
		<div class='promo-title'>
			<Circle />
			День рождения у хип-хопа, <br>
			а подарки — вам.
			<Big1 />
		</div>
		<div class='promo-text'>
			Мы выбрали культовые, по нашему мнению, треки за всю историю, а молодые артисты поделились песнями, которые
			повлияли на их творчество.
			<br><br>
			Всё это мы собрали в праздничный плейлист, который можно послушать в Звуке.
			<br><br>
			HiFi-качество, будто бы на студии Dr. Dre, бесплатно на 30 дней*
		</div>
		<PlaylistLink />
		<div class='small-important-text'>
			*доступно для клиентов, впервые оформивших Подписку СберПрайм, на Пробный Период, равный 30 дням. С момента
			истечения Пробного Периода стоимость Подписки составит 199 руб. в месяц. <br>
			Сервис «Звук» (12+) - услуги оказывает ООО «Звук», ОГРН 5177746117005, адрес: 101000, г. Москва, вн.тер.г.
			Муниципальный округ Красносельский, пер. Уланский, д. 22, стр. 1, помещ. 1Н/6. <a href='https://zvuk.com/'>https://zvuk.com/</a>
			<br>
			Подписка «СберПрайм» - услуги АО «ЦПЛ» (ОГРН 1117746689840, юр. адрес: 121170, Москва, ул. Поклонная, 3, этаж 3,
			пом. 120) по организации и обеспечению предоставления физическим лицам доступа к комплексу сервисов партнеров.
		</div>
		<Final />
		<div class='links'>
			<a href='https://sberprime.sber.ru/' target='_blank'>Подробнее о Подписке</a>
			<a href='https://sberprime.sber.ru/services_conditions' target='_blank'>Перечень партнеров, условия предоставления
				Сервисов, ограничения</a>
			<a href='https://sberprime.sber.ru/offer' target='_blank'>Оферта</a>
		</div>
		<GroznovLink />
	</div>
</div>

<style lang='scss'>
  .final-screen {
    padding-top: 0;
    padding-bottom: 32px;
    overflow-y: scroll;

    //@include scrollbar-styles;

    > .container {
      > .title {
        margin-top: 32px;
        font-family: $TTHoves, sans-serif;
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
      }

      > .caption {
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      > .promo-title {
        margin-top: 92px;
        margin-bottom: 76px;
        position: relative;
        font-family: $TTHoves, sans-serif;
        font-size: 32px;
        line-height: 40px;
        font-weight: 800;
        text-align: center;

        :global(.circle) {
          top: -50px;
          left: calc(50% - 91px / 2);
        }

        :global(.big-1) {
          position: absolute;
          left: calc(50% - 208px / 2);
          bottom: -60px;
        }
      }

      > .promo-text {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      > .small-important-text {
        margin-top: 50px;
        margin-bottom: 27px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        a {
          @include reset-link;
        }
      }

      > :global(.final) {
        width: 100%;
      }

      > .links {
        height: 60px;
        margin-top: 8px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 28px;
        row-gap: 16px;

        a {
          max-width: 179px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #FFFFFF;
          @include reset-link;

          &:nth-child(2) {
            grid-row: span 2;
          }
        }
      }
    }
  }
</style>
